/*
Template: Vito - Responsive Bootstrap 4 Admin Dashboard Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }
}
@media (max-width: 1699px) {
  .email-form .select2-container {
    width: 100% !important;
  }
  .sign-in-page {
    height: 100%;
  }
  .sign-in-detail {
    height: 100%;
  }
  body.icon-with-text .iq-menu-bt-sidebar {
    display: none;
  }
}
@media (min-width: 1300px) and (max-width: 1530px) {
  .iq-email-to-list {
    overflow-x: scroll;
    margin: 0 20px;
    padding-left: 0 !important;
  }
  .iq-email-to-list .justify-content-between {
    float: left;
    width: 900px;
  }
  .iq-chat-ui li a {
    padding: 20px 0;
  }
  .chat-sidebar-channel .avatar,
  .avatar {
    margin-right: 10px !important;
  }
  .chat-sidebar-channel .avatar > img {
    height: 40px;
    width: 40px;
  }
  .chat-sidebar-name > h6 {
    font-size: 14px;
  }
  .chat-sidebar-name span {
    font-size: 12px;
  }
}
@media (min-width: 1300px) {
  body.icon-with-text .iq-sidebar-logo a {
    margin: 0 auto;
  }
  .iq-sidebar-menu .iq-menu .iq-menu-title i {
    display: none;
  }
  body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title i {
    font-size: 20px;
    display: block;
  }
  body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title i {
    display: block;
  }
  body.sidebar-main
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    .iq-menu-title
    i {
    display: none;
    transition: all 0.45s ease 0s;
  }
  body.sidebar-main
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    .iq-menu-title
    span {
    display: block;
    transition: all 0.45s ease 0s;
  }
  body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title span {
    display: none;
  }
  .sidebar-main .iq-sidebar:hover .iq-submenu {
    opacity: 1;
    height: auto;
  }
  body.top-tab-horizontal .iq-menu-bt {
    display: none;
  }
  .sidebar-main .iq-sidebar .iq-submenu li a {
    font-size: 0;
  }
  .sidebar-main .iq-sidebar:hover .iq-submenu li a {
    font-size: 14px;
  }
  body.sidebar-main-active .iq-sidebar-menu .iq-menu .iq-menu-title i {
    font-size: 20px;
    display: block;
  }
  body.sidebar-main-active .iq-sidebar-menu .iq-menu .iq-menu-title span {
    display: none;
  }
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    .iq-menu-title
    span {
    display: block;
  }
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    .iq-menu-title
    i {
    display: none;
  }
  body.sidebar-main-active .iq-sidebar .iq-menu-bt-sidebar {
    display: none;
  }
  body.sidebar-main-active .iq-sidebar {
    width: 80px;
  }
  body.sidebar-main-active
    .iq-sidebar
    .iq-sidebar-menu
    .iq-menu
    li
    a
    .iq-arrow-right {
    opacity: 0;
  }
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    li
    a
    .iq-arrow-right {
    opacity: 1;
  }
  body.sidebar-main-active .iq-sidebar .iq-sidebar-menu .iq-menu li a span,
  body.sidebar-main-active .iq-sidebar-logo a span,
  body.sidebar-main-active .iq-sidebar-menu .iq-menu li a .badge {
    opacity: 0;
    display: none;
  }
  body.sidebar-main-active
    .iq-sidebar
    .iq-sidebar-menu
    .iq-menu
    li
    ul.iq-submenu
    li
    a {
    font-size: 0;
  }
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    li
    ul.iq-submenu
    li
    a {
    font-size: 14px;
    padding: 15px 20px;
  }
  body.sidebar-main-active .iq-sidebar:hover .iq-menu li.menu-open > a {
    border-radius: 8px 8px 0 0;
  }
  body.sidebar-main-active .iq-sidebar:hover,
  body.sidebar-main-active .iq-sidebar:hover .iq-sidebar-logo {
    width: 260px;
  }
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    li
    a
    span,
  body.sidebar-main-active .iq-sidebar:hover .iq-sidebar-logo a span,
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    li
    a
    .badge,
  body.sidebar-main-active .iq-sidebar:hover .iq-menu-bt {
    opacity: 1;
    display: inline-block;
  }
  .iq-right-fixed {
    position: fixed;
    left: auto;
    right: 0;
    top: 0;
    width: 320px;
    background: var(--iq-card-bg);
    z-index: 99;
    height: 100%;
  }
  body.sidebar-main-active.right-column-fixed .iq-top-navbar {
    margin-right: 320px;
    width: calc(100% - 460px);
  }
  body.sidebar-main-active.right-column-fixed .content-page,
  body.sidebar-main-active.right-column-fixed .iq-footer {
    margin-left: 80px;
    margin-right: 320px;
  }
  body.sidebar-main-active.right-column-fixed .iq-top-navbar.fixed-header {
    width: calc(100% - 400px);
  }
  body.sidebar-main-active
    .iq-sidebar
    .iq-sidebar-menu
    .iq-menu
    li
    ul.iq-submenu.iq-submenu-data
    li
    a {
    padding-left: 15px;
  }
  body.sidebar-main-active
    .iq-sidebar:hover
    .iq-sidebar-menu
    .iq-menu
    li
    ul.iq-submenu.iq-submenu-data
    li
    a {
    padding-left: 50px;
  }

  /* Horizontal Menu Page */
  body.iq-page-menu-horizontal .iq-top-navbar {
    width: auto;
    left: 30px;
  }
  body.iq-page-menu-horizontal .iq-top-navbar.fixed-header {
    left: 0;
  }
  body.iq-page-menu-horizontal
    .iq-sidebar-menu
    .iq-menu
    li
    a[aria-expanded="true"] {
    color: var(--iq-primary);
    background: transparent;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li a {
    padding: 28px 30px 28px 0;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li:last-child a {
    padding-right: 0;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li {
    position: relative;
  }
  .iq-page-menu-horizontal .content-page {
    margin-left: 0;
  }
  .iq-page-menu-horizontal .iq-footer {
    margin-left: 0;
  }
  .iq-page-menu-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
    display: block;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul {
    display: block;
    position: absolute;
    top: 100%;
    padding: 0;
    min-width: 200px;
    background: var(--iq-white);
    z-index: 9;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
    transition: all 0.45s ease 0s;
    -webkit-transition: all 0.45s ease 0s;
    -moz-transition: all 0.45s ease 0s;
    -o-transition: all 0.45s ease 0s;
    transform: scale(1, 0);
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    -webkit-transform-origin: 0 0 0;
    -moz-transform-origin: 0 0 0;
    -o-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul #sidebar-scrollbar {
    height: 50vh;
  }
  .iq-menu-horizontal .iq-sidebar-menu li.active > a,
  .iq-menu-horizontal .iq-sidebar-menu li:hover > a {
    color: var(--iq-primary);
    background: transparent;
  }
  .iq-menu-horizontal
    .iq-sidebar-menu
    .iq-menu
    li.active
    .iq-submenu
    li.active
    > a {
    background-color: transparent;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active {
    margin: 0;
  }
  .iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu {
    display: block;
    transition: all 0.45s ease 0s;
    -webkit-transition: all 0.45s ease 0s;
    -moz-transition: all 0.45s ease 0s;
    -o-transition: all 0.45s ease 0s;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right,
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover .iq-arrow-right {
    transform: rotate(90deg);
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li li .iq-arrow-right,
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover li .iq-arrow-right {
    transform: rotate(0deg);
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li li a .iq-arrow-right {
    transform: rotate(0);
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul li a {
    padding: 12px 15px;
  }
  .iq-menu-horizontal .iq-sidebar-menu li > .iq-submenu.iq-submenu-data {
    opacity: 0;
    visibility: hidden;
    left: 100%;
    top: 0;
    transition: transform 0.2s;
    transform: translateX(-50px);
  }
  .iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu.iq-submenu-data {
    opacity: 1;
    visibility: visible;
    transition: all 0.45s ease 0s;
    transform: translateX(0);
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li a:hover,
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active > a,
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open > a {
    background: transparent !important;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li > a::before {
    display: none;
  }

  /* top-tab-horizontal */
  .top-tab-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
    display: block;
  }
  .top-tab-horizontal .iq-sidebar-logo {
    padding: 0;
    display: block;
  }
  .top-tab-horizontal .content-page {
    padding: 90px 15px 0;
    width: 100%;
    margin-left: 0;
  }
  .top-tab-horizontal .iq-footer {
    margin-left: 0;
  }
  .tab-menu-horizontal {
    background: var(--iq-primary) !important;
    margin-top: 75px;
    transition: all 0.45s ease 0s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
  body.top-tab-horizontal .tab-menu-horizontal.menu-down {
    top: -60px;
    transition: all 0.45s ease 0s;
    opacity: 0;
  }
  body.top-tab-horizontal .tab-menu-horizontal.menu-up {
    top: 0;
    opacity: 1;
    transition: all 0.45s ease 0s;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li > ul.iq-submenu {
    display: block;
    position: absolute;
    top: 150%;
    background: var(--iq-white);
    min-width: 260px;
    z-index: 9;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
    transition: all 0.45s ease 0s;
    border-radius: 0 0 8px 8px;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > ul.iq-submenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
    transition: all 0.45s ease 0s;
  }
  .iq-menu-horizontal-tab
    .iq-sidebar-menu
    .iq-menu
    li
    > ul.iq-submenu#ui-elements {
    height: 350px;
    overflow-y: scroll;
  }
  .iq-menu-horizontal-tab
    .iq-sidebar-menu
    .iq-menu
    li
    > ul.iq-submenu
    ul.iq-submenu-data {
    left: 130%;
    top: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: all 0.45s ease 0s;
    border-radius: 0 8px 8px 8px;
  }
  .iq-menu-horizontal-tab
    .iq-sidebar-menu
    .iq-menu
    li
    > ul.iq-submenu
    li:hover
    ul.iq-submenu-data {
    left: 100%;
    opacity: 1;
    visibility: visible;
    transition: all 0.45s ease 0s;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li {
    padding: 10px 0;
    margin: 0;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li.active {
    margin: 0;
  }
  .iq-menu-horizontal-tab
    .iq-sidebar-menu
    .iq-menu
    li.active
    .iq-submenu
    li.active
    > a {
    background-color: transparent;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li > a {
    background: transparent;
    border-radius: 8px;
    padding: 10px 20px;
    color: var(--iq-white);
    transition: all 0.5s ease-out 0s;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu li a[aria-expanded="true"] {
    background: transparent;
  }
  .iq-menu-horizontal-tab .iq-sidebar-menu .iq-menu > li:hover > a {
    color: var(--iq-primary);
    background: var(--iq-light-primary);
    transition: all 0.5s ease-out 0s;
  }
  body.top-tab-horizontal .iq-top-navbar .top-menu {
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
    min-height: 73px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: inline-block;
    z-index: 99;
    background: var(--iq-white);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
    box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  }
  body.top-tab-horizontal .iq-top-navbar {
    position: static;
    box-shadow: none;
  }

  /* two sidebar */
  body.two-sidebar .iq-top-navbar {
    width: calc(100% - 375px);
  }
  body.two-sidebar .iq-top-navbar.fixed-header {
    width: calc(100% - 320px);
  }
  body.two-sidebar.sidebar-main .iq-top-navbar {
    width: calc(100% - 115px);
  }
  body.two-sidebar.sidebar-main .iq-top-navbar.fixed-header {
    width: calc(100% - 60px);
  }

  /* sidebar icon with text  */
  body.icon-with-text .iq-sidebar {
    width: 150px;
    overflow: unset;
  }
  body.icon-with-text .iq-sidebar #sidebar-scrollbar {
    overflow: unset !important;
  }
  body.icon-with-text .content-page {
    margin-left: 150px;
  }
  body.icon-with-text.sidebar-main .iq-sidebar {
    width: 80px;
  }
  body.icon-with-text.sidebar-main .iq-sidebar:hover,
  body.icon-with-text.sidebar-main .iq-sidebar:hover .iq-sidebar-logo {
    width: 180px;
  }
  body.icon-with-text.sidebar-main .iq-sidebar-logo {
    width: 80px;
  }
  body.icon-with-text.sidebar-main .content-page,
  body.icon-with-text.sidebar-main .iq-footer {
    margin-left: 80px;
  }
  body.icon-with-text .iq-sidebar-logo {
    width: 150px;
    position: relative;
    overflow: hidden;
    height: 74px;
    z-index: 9;
    flex-direction: column;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu > li > a {
    display: flex;
    flex-direction: column;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li > a > i {
    margin: 0 0 10px;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li li > a > i {
    margin: 0 10px 0 0;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li ul {
    background: var(--iq-blue-light);
    position: absolute;
    display: block;
    top: 0;
    left: 100%;
    padding: 0;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.2s;
    transform: translateX(50px);
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li:hover > ul {
    opacity: 1;
    visibility: visible;
    transition: all 0.45s ease 0s;
    transform: translateX(0);
  }
  body.icon-with-text
    .iq-sidebar.sidebar-dark
    .iq-sidebar-menu
    .iq-menu
    li
    ul
    li
    a {
    padding-left: 15px;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li a {
    padding: 15px;
  }
  body.icon-with-text .iq-footer {
    margin-left: 150px;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
    position: absolute;
    left: auto;
    right: 0;
    top: 27px;
    font-size: 12px;
  }
  body.icon-with-text
    .iq-sidebar-menu
    .iq-menu
    .iq-submenu
    li
    a
    .iq-arrow-right {
    top: 15px;
  }
  body.icon-with-text
    .iq-sidebar-menu
    .iq-menu
    li
    a[aria-expanded="true"]
    .iq-arrow-right,
  body.icon-with-text .iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right,
  body.icon-with-text .iq-sidebar-menu .iq-menu li.active .iq-arrow-right {
    transform: rotate(0deg);
  }
  body.icon-with-text .iq-top-navbar {
    width: calc(100% - 210px);
  }
  body.icon-with-text .iq-top-navbar.fixed-header {
    width: calc(100% - 150px);
  }
}
@media (max-width: 1399px) {
  .animation-card .an-img .bodymovin {
    margin-bottom: -28px;
  }
  .animation-card .an-img {
    margin-top: 0;
  }
  .iq-search-bar .searchbox {
    width: 400px;
  }
}
@media (max-width: 1299px) {
  .iq-sidebar-menu .iq-menu .iq-menu-title i {
    display: none;
  }
  .iq-circle-progressbar .percent {
    font-size: 1.3rem !important;
  }
  .iq-circle-progressbar svg {
    width: 80px !important;
    height: 80px !important;
  }
  .iq-circle-progressbar {
    margin: 0 auto;
    width: 80px !important;
    height: 80px !important;
  }
  .content-page,
  body.sidebar-main .content-page {
    margin-left: 0;
    padding: 120px 0 0;
  }
  .iq-top-navbar,
  body.sidebar-main .iq-top-navbar {
    width: auto !important;
    right: 0 !important;
    left: 0;
    padding: 0 15px;
  }
  body.iq-page-menu-horizontal .iq-top-navbar {
    width: auto;
    right: 0 !important;
    left: 0 !important;
    padding: 0 15px;
  }
  .iq-top-navbar.fixed-header {
    width: 100%;
    left: 0;
  }
  .iq-footer,
  body.sidebar-main .iq-footer {
    padding: 15px 10px;
    margin-left: 0;
  }
  .iq-sidebar {
    display: inline-block;
    z-index: 99;
    left: -260px;
  }
  .iq-top-navbar .iq-navbar-custom .navbar-breadcrumb {
    display: none;
  }
  .iq-sidebar-logo {
    width: auto;
  }
  .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    display: inline-block;
    position: absolute;
    /* right: 440px; */
    opacity: 1;
    color: #475cc7;
    background: var(--iq-bg-light-color);
  }
  body.sidebar-main .iq-top-navbar .iq-sidebar-logo a span {
    opacity: 1;
    display: inline-block;
  }
  body.sidebar-main .iq-sidebar {
    width: 269px;
    left: 0;
    z-index: 999;
  }
  body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span {
    display: inline-block;
    opacity: 1;
  }
  body.sidebar-main .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
    display: inline-block;
  }
  .iq-email-to-list ul li {
    margin: 0 2px 0 0;
  }
  .an-img-two {
    width: 600px;
    top: -118px;
  }
  .iq-menu-horizontal {
    position: absolute;
    left: -100%;
    right: 0;
    width: 100%;
    opacity: 0;
    top: 95px;
    width: 260px;
    background: var(--iq-white);
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.45s ease 0s;
  }
  .iq-page-menu-horizontal.sidebar-main .iq-menu-horizontal {
    opacity: 1;
    left: 0;
    transition: all 0.45s ease 0s;
  }
  .iq-menu-horizontal ul.iq-menu.d-flex {
    display: block !important;
    background: var(--iq-white);
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li a {
    padding: 15px 20px;
  }
  .iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul {
    position: static;
    box-shadow: none;
  }
  .iq-search-bar .searchbox {
    width: 100%;
  }
  .iq-right-fixed {
    margin: 0 15px;
  }
  body.sidebar-main-active .iq-sidebar-logo {
    display: none;
  }
  .sidebar-main .iq-sidebar:hover .iq-submenu li a {
    font-size: 14px;
  }
  body.sidebar-main .iq-top-navbar.fixed-header {
    left: 0;
    right: 0;
    width: 100%;
  }
  body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span,
  body.sidebar-main .iq-sidebar-logo a span,
  body.sidebar-main .iq-sidebar-menu .iq-menu li a .badge {
    opacity: 1;
    display: block;
  }
  .iq-page-menu-horizontal .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    right: 320px;
  }
  body.two-sidebar.sidebar-main .content-page,
  body.two-sidebar .content-page {
    margin-left: 70px;
  }
  .two-sidebar .iq-footer {
    margin-left: 60px;
  }
  body.two-sidebar .iq-top-navbar {
    width: calc(100% - 70px);
    left: auto;
  }
  body.two-sidebar .iq-top-navbar.fixed-header {
    width: calc(100% - 60px);
  }
  body.two-sidebar.sidebar-main .iq-sidebar {
    width: 260px;
  }
  body.top-tab-horizontal .iq-sidebar-logo {
    display: block;
    padding-top: 0;
  }
  body.top-tab-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
    display: block;
  }
  .tab-menu-horizontal {
    position: fixed;
    z-index: 999;
    top: 75px;
    left: -260px;
    width: 260px;
    overflow: hidden;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background: var(--iq-white);
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  body.top-tab-horizontal.sidebar-main .tab-menu-horizontal {
    left: 0;
  }
  body.top-tab-horizontal .iq-menu-horizontal-tab {
    width: 100%;
  }
  .iq-sidebar-menu .iq-menu {
    display: block !important;
  }
  body.top-tab-horizontal .iq-top-navbar,
  body.body.top-tab-horizontal.sidebar-main .iq-top-navbar {
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
  }
  body.top-tab-horizontal .content-page,
  body.top-tab-horizontal.sidebar-main .content-page {
    padding: 100px 0 0;
  }
  body.icon-with-text .iq-sidebar-menu .iq-menu li ul {
    background: var(--iq-blue-light);
  }
  .iq-sidebar.sidebar-dark-blue
    .iq-sidebar-menu
    .iq-menu
    li.active
    .iq-submenu
    li.active
    > a {
    border-radius: 0;
  }
  .top-menu {
    background: var(--iq-white);
  }
  body.top-tab-horizontal.sidebar-main .iq-menu-bt {
    opacity: 1;
    display: block;
  }
  .iq-sidebar.sidebar-dark-blue
    .iq-sidebar-menu
    .iq-menu
    li
    a[aria-expanded="true"] {
    border-radius: 0 8px 0 0;
  }
  .iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li ul {
    border-radius: 0 0 8px 0;
  }
  body.sidebar-main .iq-menu-bt-sidebar .iq-menu-bt {
    display: inline-block;
  }
  body.sidebar-main .iq-sidebar-logo {
    width: 100%;
  }
  body.sidebar-main.iq-page-menu-horizontal .iq-sidebar-logo {
    width: auto;
  }
  body.sidebar-main.top-tab-horizontal .iq-sidebar-logo {
    width: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .iq-chat-ui li a {
    padding: 20px 0;
  }
  .chat-sidebar-channel .avatar,
  .avatar {
    margin-right: 10px !important;
  }
  .chat-sidebar-channel .avatar > img {
    height: 40px;
    width: 40px;
  }
  .chat-sidebar-name > h6 {
    font-size: 14px;
  }
  .chat-sidebar-name span {
    font-size: 12px;
  }
}
@media (max-width: 1199px) {
  .an-img-two {
    display: none;
  }
  .iq-booking-no li .iq-seat {
    width: 35px;
    height: 45px;
  }
  .iq-booking-no li {
    width: 4.7%;
  }
  .iq-email-to-list {
    overflow-x: scroll;
    margin: 0 20px;
    padding-left: 0 !important;
  }
  .iq-email-to-list ul li {
    margin: 0 5px 0 0;
  }
  .iq-email-to-list .justify-content-between {
    float: left;
    width: 900px;
  }
  .sign-in-from {
    padding: 0 30px;
  }
  .sign-in-detail {
    padding: 100px 50px;
  }
}
@media (max-width: 991px) {
  .an-img-two .bodymovin {
    display: none;
  }
  .display-1 {
    font-size: 4rem;
    font-weight: 300;
  }
  .display-2 {
    font-size: 3.5rem;
    font-weight: 300;
  }
  .display-3 {
    font-size: 3rem;
    font-weight: 300;
  }
  .display-4 {
    font-size: 2.5rem;
    font-weight: 300;
  }
  .display-5 {
    font-size: 2rem;
    font-weight: 300;
  }
  .display-6 {
    font-size: 1.5rem;
    font-weight: 300;
  }
  .iq-top-navbar .navbar {
    position: relative;
  }
  .iq-top-navbar .navbar-toggler {
    right: 100px;
    top: 18px;
    position: absolute;
    color: var(--iq-primary);
    background: var(--iq-bg-light-color);
    border: none;
    padding: 7px 10px;
    border-radius: 8px;
    font-size: 24px;
  }
  .navbar-collapse {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    background: var(--iq-white);
    -webkit-box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15);
    -moz-box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15);
    box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15);
  }
  .navbar-nav.navbar-list {
    float: right;
    display: inline-block;
  }
  .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    top: 18px;
  }
  .animation-card .an-img .bodymovin {
    margin-bottom: 0;
    margin-left: 0;
    width: 100%;
  }
  .iq-footer,
  .iq-footer .col-lg-6.text-right {
    text-align: center !important;
  }
  .iq-booking-index {
    overflow-x: scroll;
  }
  .seat-booking {
    width: 980px;
    position: relative;
  }
  .seat-booking .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    width: 8.333333%;
  }
  .seat-booking .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    width: 83.333333%;
  }
  .iq-booking-offer {
    padding: 30px;
  }
  .offer-an-img {
    position: static;
    width: 100%;
  }
  .sign-in-from {
    padding: 0 20px;
  }
  .iq-maintenance .col-lg-4 {
    margin-bottom: 15px;
  }
  .chat-data-left {
    position: absolute;
    left: 0;
    max-width: 320px;
    top: 0;
    z-index: 9;
    background: var(--iq-white);
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.45s ease 0s;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
    overflow-y: scroll;
    height: 100%;
  }
  .chat-sidebar-channel {
    overflow: auto;
    height: auto;
    padding-left: 0 !important;
  }
  .chat-search {
    padding-left: 0 !important;
  }
  .chat-data-left.show {
    transform: translateX(0);
    opacity: 1;
    transition: all 0.45s ease 0s;
  }
  button.close-btn-res {
    display: block;
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    top: 10px;
    left: auto;
    right: 0;
  }
  .chat-head header {
    padding-left: 15px;
  }
  div#sidebar-toggle {
    display: block;
  }
  #sidebar-toggle {
    background: var(--iq-color-light);
    padding: 12px 10px;
    margin-right: 15px;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    line-height: 17px;
    text-align: center;
    color: #475cc7;
  }
  #chat-user-detail-popup {
    overflow-y: scroll;
    padding-bottom: 20px;
  }
  #user-detail-popup {
    overflow: scroll;
  }
  ul.profile-img-gallary li img {
    width: 100%;
  }
  .profile-feed-items li a {
    margin: 0 5px 0 0;
  }
  .profile-left {
    order: 2;
  }
  .profile-center {
    order: 1;
  }
  .profile-right {
    order: 3;
  }
  .iq-edit-profile .nav-link {
    font-size: 14px;
  }
  .stepwizard-row .wizard-step a {
    padding: 20px 15px;
    margin: 10px 10px;
  }
  .navbar-list li .caption {
    display: none;
  }
  .navbar-list li img {
    margin-right: 0 !important;
  }
  .iq-page-menu-horizontal .iq-top-navbar .navbar {
    display: inherit;
  }
  body.sidebar-main .iq-sidebar-logo a span {
    opacity: 1;
    display: block;
  }
  .iq-page-menu-horizontal .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    right: 145px;
  }
  body.iq-page-menu-horizontal .iq-navbar-custom.d-flex {
    display: flex !important;
  }
  body.iq-page-menu-horizontal .iq-top-navbar .navbar-toggler {
    right: 100px;
  }
  .iq-page-menu-horizontal .iq-sidebar-logo {
    padding-top: 0px;
  }
  .top-tab-horizontal .iq-menu-bt {
    margin-right: 75px;
  }

  .iq-page-menu-horizontal .iq-top-navbar .navbar {
    position: static;
    z-index: 0;
  }
  .iq-page-menu-horizontal .navbar-collapse {
    top: 100%;
    z-index: -1;
  }
  .iq-page-menu-horizontal .iq-top-navbar .navbar-toggler {
    top: 38px;
  }
  .iq-page-menu-horizontal .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    top: 38px;
  }
  .iq-page-menu-horizontal .iq-top-navbar .iq-sub-dropdown .iq-sub-card img {
    margin-right: 5px !important;
  }
  /*.iq-page-menu-horizontal .iq-sidebar-menu {position: relative;z-index: 99;}*/
  body.icon-with-text .iq-menu-bt-sidebar {
    display: unset;
  }
}
@media (max-width: 979px) {
  .iq-circle-progressbar .percent {
    font-size: 2.5rem !important;
  }
  .iq-circle-progressbar svg {
    width: 200px !important;
    height: 200px !important;
  }
  .iq-circle-progressbar {
    margin: 0 auto;
    width: 200px !important;
    height: 200px !important;
  }
  .pro-img img{
    display: none;
  }
}
@media (max-width: 767px) {
  .table {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 1rem;
    display: block;
    overflow-x: auto;
  }
  .table thead,tbody{
    width: 100% !important;
  }
  .display-1 {
    font-size: 2.5rem;
    font-weight: 500;
  }
  .display-2 {
    font-size: 2.3rem;
    font-weight: 500;
  }
  .display-3 {
    font-size: 2rem;
    font-weight: 500;
  }
  .display-4 {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .display-5 {
    font-size: 1.3rem;
    font-weight: 500;
  }
  .display-6 {
    font-size: 1rem;
    font-weight: 500;
  }
  .search-box .search-input {
    width: 280px;
  }
  .navbar-nav.navbar-list {
    display: inline-block;
  }
  .sign-info {
    text-align: center;
    margin-bottom: 30px;
  }
  .iq-social-media {
    width: 100%;
  }
  .iq-social-media li {
    float: none;
    display: inline-block;
  }
  .sign-in-from button {
    margin-top: 10px;
  }
  .sign-in-from .custom-control.custom-checkbox {
    display: block !important;
  }
  .sign-in-detail {
    padding: 50px 20px;
  }
  .sign-in-from {
    padding: 20px;
  }
  .iq-error h1 {
    font-size: 12rem;
  }
  .user-detail {
    margin-bottom: 15px;
    padding-left: 0 !important;
  }
  .user-detail .d-flex {
    display: block !important;
    text-align: center;
  }
  .profile-img {
    text-align: center;
    padding-right: 0 !important;
  }
  .profile-feed-items {
    width: 100%;
    justify-content: center;
  }
  .iq-edit-profile .nav-link {
    border: none;
  }
  .user-list-files.d-flex.float-right {
    display: block !important;
    text-align: center;
    margin-top: 30px;
    width: 100%;
  }
  .table-responsive #exampleInputSearch {
    width: 100%;
  }
  .wizard-step {
    width: 50%;
  }
  .iq-advance-course .left-block .d-flex {
    display: block !important;
  }
  .iq-advance-course.d-flex {
    display: block !important;
    text-align: center;
  }
  .right-block .image-absulute.image-absulute-1 {
    right: 50px;
  }
  .right-block .image-absulute.image-absulute-2 {
    left: 40px;
    right: auto;
    top: auto;
    bottom: 50px;
  }
  ul.iq-edit-profile li:first-child a {
    border-radius: 8px 8px 0 0;
  }
  ul.iq-edit-profile li:last-child a {
    border-radius: 0 0 8px 8px;
  }
  .ckeckout-product-lists > .d-flex,
  .ckeckout-product-lists > .d-flex > .d-flex {
    display: block !important;
    text-align: center;
  }
  .checkout-amount-data > .d-flex {
    display: block !important;
  }
  .checkout-product-details {
    padding-left: 0 !important;
    margin: 15px 0 0 0 !important;
  }
  .checkout-button {
    justify-content: center;
  }
  .counter {
    font-size: 29px;
  }
}
@media (max-width: 557px) {
  ul#topbar-data-icon {
    margin: -15px 10px 0;
  }
  .iq-search-bar {
    padding: 0 15px;
    width: 100%;
    margin: 15px 0 0;
  }
  .table thead,tbody{
    width: 100% !important;
  }
  .iq-top-navbar .navbar {
    width: 100%;
  }
  .iq-top-navbar .navbar-toggler,
  .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    
    /* top: 30px; */
  }
  /* .iq-top-navbar .navbar-toggler { right: 100px; } */
  /* .iq-top-navbar .iq-navbar-custom .iq-menu-bt { right: 145px; } */
  .navbar-collapse {
    top: 95px;
    border-radius: 8px;
  }
  .content-page,
  body.sidebar-main .content-page {
    padding: 140px 0 0;
  }
  .iq-top-navbar .iq-sub-dropdown {
    width: 225px;
    left: auto;
    right: 0;
    margin: 0 auto;
  }
  /* .navbar-left { margin-bottom: 20px; } */
  .topbar-menu-icon li a {
    margin: 0 0 0 10px;
  }
  /* .iq-page-menu-horizontal .iq-top-navbar .navbar-toggler, .iq-top-navbar .iq-navbar-custom .iq-menu-bt { top: 18px; } */
  .iq-page-menu-horizontal .navbar-collapse {
    top: 75px;
  }
  .top-tab-horizontal .iq-top-navbar .navbar {
    display: flex !important;
    width: auto;
  }
  .top-tab-horizontal .iq-top-navbar .navbar-toggler {
    top: 18px;
  }
  .icon-with-text .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    top: 108px;
  }
  /* .iq-top-navbar .iq-navbar-custom .iq-menu-bt { top: 108px; } */

  .iq-page-menu-horizontal .iq-top-navbar .navbar-toggler {
    top: 38px;
  }
  .iq-page-menu-horizontal .navbar-collapse {
    top: 100%;
  }
}
@media (max-width: 479px) {
  .display-1 {
    font-size: 2rem;
    font-weight: 500;
  }
  .display-2 {
    font-size: 1.8rem;
    font-weight: 500;
  }
  .display-3 {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .display-4 {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .display-5 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .iq-circle-progressbar .percent {
    font-size: 2rem !important;
  }
  .iq-circle-progressbar svg {
    width: 150px !important;
    height: 150px !important;
  }
  .iq-circle-progressbar {
    margin: 0 auto;
    width: 150px !important;
    height: 150px !important;
  }
  .iq-card-body {
    padding: 15px;
  }
  .iq-sidebar-logo a span {
    display: block;
  }
  body.sidebar-main .iq-top-navbar .iq-sidebar-logo a span {
    opacity: 0;
    display: none;
  }
  .iq-error h1 {
    font-size: 8rem;
  }
  .chat-head header {
    overflow-x: scroll;
  }
  #chat-user-detail-popup {
    width: 300px;
  }
  .profile-feed-items {
    display: block !important;
  }
  .profile-feed-items li {
    margin: 0 0 20px 0;
  }
  ul.header-nav li {
    height: 35px;
    width: 35px;
    line-height: 35px;
    margin: 0 5px 0 0;
    font-size: 16px;
  }
  .comment-area.p-3 > div {
    display: block !important;
  }
  .comment-area.p-3 > div > div {
    margin-bottom: 10px;
  }
  .profile-feed-items li a {
    text-align: center;
  }
  .wizard-step {
    width: 100%;
  }
  .iq-page-menu-horizontal .navbar-collapse,
  .top-tab-horizontal .navbar-collapse {
    top: 75px;
  }
  .iq-page-menu-horizontal .iq-top-navbar .navbar-toggler,
  .iq-page-menu-horizontal .iq-top-navbar .iq-navbar-custom .iq-menu-bt,
  .top-tab-horizontal .iq-top-navbar .navbar-toggler,
  .top-tab-horizontal .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    top: 18px;
  }
  .iq-page-menu-horizontal .content-page,
  body.sidebar-main.iq-page-menu-horizontal .content-page {
    padding: 120px 0 0;
  }
  .top-tab-horizontal .content-page,
  body.sidebar-main.top-tab-horizontal .content-page {
    padding: 100px 0 0;
  }
  .iq-page-menu-horizontal .iq-top-navbar .navbar-toggler,
  .iq-page-menu-horizontal .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
    top: 38px;
  }
  div#powered-by {
    margin-left: unset;
    display: none !important;
  }
  /* .iq-sidebar-logo img {height: 45px;
    width: 120px;} */
  .iq-page-menu-horizontal .navbar-collapse {
    top: 100%;
  }
  .iq-sidebar-logo a span {
    display: none;
  }
}

@media (max-width: 359px) {
}
