/* @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  /* font-family: "Josefin Sans", sans-serif !important; */
  font-family: "Nunito Sans", sans-serif !important;
}

::-moz-selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

::selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

/* Definition Lists */
dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0px;
}

.description-text p span,
.description-text h1 span,
.description-text h2 span,
.description-text h3 span,
.description-text h4 span,
.description-text h5 span,
.description-text pre span,
.description-text h6 span {
  color: rgb(255 255 255) !important;
  font-size: 14px !important;
  background-color: rgb(255 255 255 / 0%) !important;
  text-wrap: wrap !important;
}

.sun-editor .se-btn-list.default_value {
  background-color: #143340 !important;
  border: none !important;
}

.sun-editor .se-btn-list:hover {
  background-color: #112935 !important;
}

.sun-editor .se-btn:enabled.on {
  background-color: #112935 !important;
  border-color: #10263cc7 !important;
  color: #ffff !important;
}

.sun-editor .se-btn:enabled:hover {
  background-color: #112935 !important;
  border-color: #10263cc7 !important;
  color: #ffff !important;
}

.sun-editor .se-list-layer {
  background-color: #253942 !important;
}

/* Table */

/* table { border: 1px solid #eaeaea; width: 100%; }
table td, table th { border: 1px solid #eaeaea; } */
a:hover {
  text-decoration: none;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-menu {
  border-radius: 8px;
}

/* scroller */
.scroller {
  overflow-y: auto;
  scrollbar-color: var(--iq-primary) var(--iq-color-light);
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: var(--iq-primary);
}

.scroller::-webkit-scrollbar-track {
  background-color: var(--iq-color-light);
}

#sidebar-scrollbar {
  overflow-y: scroll;
}

#sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1c313cc7;
}

#sidebar-scrollbar::-webkit-scrollbar-track {
  background-color: var(--iq-color-light);
}

/* Form */
#sidebar-scrollbar {
  height: 100%;
}

/*---------------------------------------------------------------------
                               Media Support
-----------------------------------------------------------------------*/
.media-support {
  display: inline-block;
  width: 100%;
}

.media-support-user-img img {
  height: 60px;
}

.media-support-header {
  display: flex;
  align-items: flex-start;
}

.media-support-info {
  flex: 1;
}

.projects-media img {
  height: 60px;
}

.iq-media-group .iq-media {
  margin-left: -20px;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-media-group .iq-media:hover {
  z-index: 9;
}

.iq-media-group .iq-media img,
.iq-media-group .iq-media.border {
  border: 2px solid var(--iq-light-border);
}

.iq-media-group .iq-media:first-child {
  margin-left: 0;
}

.iq-media {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.iq-media:hover {
  text-decoration: none;
}

.media-pl {
  text-align: center;
}

/*---------------------------------------------------------------------
                                  Best Sellers
-----------------------------------------------------------------------*/
.media-sellers {
  display: flex;
  align-items: flex-start;
}

.media-sellers-media-info {
  flex: 1;
}

.media-sellers-img img {
  height: 80px;
}

.iq-card .list-star li {
  margin-right: 0;
}

/*---------------------------------------------------------------------
                                  Profile-Avatar
-----------------------------------------------------------------------*/
.iq-profile-avatar {
  position: relative;
}

.iq-profile-avatar:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  border: 2px solid var(--iq-white);
  background-color: var(--iq-body-text);
  height: 16px;
  width: 16px;
  right: 0;
  bottom: 3px;
}

.iq-profile-avatar.status-online:before {
  background-color: var(--iq-success);
}

.iq-profile-avatar.status-away:before {
  background-color: var(--iq-warning);
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 15px 0 0;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--iq-white);
  border: 1px solid var(--iq-icon-color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: var(--iq-border-light);
  border-color: var(--iq-primary);
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--iq-body-text);
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid var(--iq-light-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*---------------------------------------------------------------------
                                  Countdown
-----------------------------------------------------------------------*/
.iq-comingsoon {
  margin-top: 80px;
}

.countdown {
  margin: 60px 0 0;
  padding: 0;
}

.countdown li {
  margin: 0 25px 0 0;
  padding: 45px 0;
  list-style: none;
  width: 22.8%;
  display: inline-block;
  text-align: center;
  line-height: normal;
  background: var(--iq-white);
  float: left;
  color: var(--iq-body-text);
  border-radius: 8px;
}

.countdown li span {
  font-size: 60px;
  display: inline-block;
  width: 100%;
  color: var(--iq-primary);
}

.countdown li:last-child {
  margin-right: 0;
}

.iq-comingsoon-form {
  position: relative;
}

.iq-comingsoon-form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 9px 30px;
}

/*---------------------------------------------------------------------
                                  Progressbar
-----------------------------------------------------------------------*/
.iq-progress-bar {
  background: var(--iq-progress-light);
  box-shadow: 0 0 0;
  height: 6px;
  margin: 0;
  position: relative;
  width: 100%;
  border-radius: 4px;
}

.iq-progress-bar > span {
  /* background: var(--iq-primary) none repeat scroll 0 0; */
  display: block;
  height: 100%;
  width: 0;
  border-radius: 4px;
}

.iq-progress-bar.iq-progress-bar-icon > span:after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: var(--border-radius-circle);
  background: var(--white);
  border: 3px solid transparent;
  position: absolute;
  bottom: -3px;
  right: 0;
  z-index: 1;
}

.iq-progress-bar.iq-progress-bar-icon > span.bg-primary:after {
  border-color: var(--iq-primary);
  border-radius: 8px;
}

.iq-progress-bar.iq-progress-bar-icon > span.bg-danger:after {
  border-color: var(--iq-danger);
}

.iq-progress-bar.iq-progress-bar-icon > span.bg-success:after {
  border-color: var(--iq-success);
}

.iq-progress-bar.iq-progress-bar-icon > span.bg-warning:after {
  border-color: var(--iq-warning);
}

.iq-progress-bar.iq-progress-bar-icon > span.bg-info:after {
  border-color: var(--iq-info);
  border-radius: 8px;
}

.iq-progress-bar.iq-progress-bar-icon > span.bg-dark:after {
  border-color: var(--iq-dark);
}

.iq-progress-bar .progress-text {
  position: absolute;
  /* right: -10px; */
  top: -30px;
  font-size: 13px;
  font-weight: 500;
}

/*--------------*/
.iq-card-header select.form-control {
  background: var(--iq-light-border);
  border: none;
  font-size: 14px;
  margin-right: 15px;
}

.iq-card-header select.form-control.width-100 {
  width: 180px;
}

/*---------------------------------------------------------------------
                             Badge Img Tag
-----------------------------------------------------------------------*/
.badge-img-tag {
  position: absolute;
  top: 15px;
  left: 15px;
}

/*---------------------------------------------------------------------
                             list Style 1
-----------------------------------------------------------------------*/
.iq-list-style-1 {
  margin: 0;
  padding: 0;
}

.iq-list-style-1 li {
  display: inline-block;
  float: left;
  list-style-type: none;
  margin-right: 15px;
}

.iq-list-style-1 li:last-child {
  margin-right: 0;
}

.iq-list-style-1 li a {
  color: var(--iq-icon-color);
}

.iq-list-style-1 li a i {
  color: var(--iq-icon-color);
  margin-right: 5px;
}

.iq-list-style-1 li a:hover,
.iq-list-style-1 li a:focus {
  text-decoration: none;
  color: var(--iq-primary);
}

.iq-list-style-1.text-white li a,
.iq-list-style-1.text-white li a i {
  color: var(--iq-white);
}

.list-inline.text-white li a {
  color: var(--iq-white);
}

.list-inline.text-white li a:hover {
  opacity: 0.6;
  text-decoration: none;
}

/*---------------------------------------------------------------------
                             Icon Box
-----------------------------------------------------------------------*/
.iq-icon-box {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  margin-right: 10px;
  font-size: 22px;
  text-decoration: none;
}

.iq-icon-box:hover {
  text-decoration: none;
}

/*---------------------------------------------------------------------
                             Tasks Card
-----------------------------------------------------------------------*/
.tasks-card {
  padding: 0 0 15px 0;
  border-bottom: 1px solid var(--iq-light-border);
  margin: 0 0 15px 0;
}

.tasks-card .close {
  font-size: 16px;
}

.tasks-card:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

/*---------------------------------------------------------------------
                               Sign In
-----------------------------------------------------------------------*/
.sign-in-detail {
  color: var(--iq-white);
  background: rgba(130, 122, 243, 1) !important;
}

.sign-in-page {
  height: 100vh;
}

.sign-in-detail {
  padding: 100px;
  height: 100vh;
}

.sign-in-logo {
  display: inline-block;
  width: 100%;
}

.sign-in-logo img {
  height: 50px;
}

.sign-in-from {
  padding: 0 60px;
}

.sign-info {
  border-top: 1px solid var(--iq-dark-border);
  margin-top: 30px;
  padding-top: 20px;
}

.iq-social-media {
  margin: 0;
  padding: 0;
  float: right;
}

.iq-social-media li {
  list-style: none;
  float: left;
  margin-right: 10px;
}

.iq-social-media li:last-child {
  margin-right: 0;
}

.iq-social-media li a {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: rgba(130, 122, 243, 0.2);
  color: var(--iq-primary) !important;
}

.iq-social-media li a:hover {
  text-decoration: none;
}

.dash-hover-blank:hover {
  cursor: pointer;
  background-color: var(--iq-light-border);
}

/*---------------------------------------------------------------------
                               Waves Effect
-----------------------------------------------------------------------*/
.iq-waves-effect {
  position: relative;
  overflow: hidden;
}

/*  Ripple */
.ripple {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(71, 92, 199, 0.1);
  transform: scale(0);
  position: absolute;
  opacity: 1;
}

.rippleEffect {
  animation: rippleDrop 0.6s linear;
}

@keyframes rippleDrop {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/*---------------------------------------------------------------------
                                Sidebar
-----------------------------------------------------------------------*/
.iq-sidebar {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 268px;
  height: 100%;
  background: var(--iq-card-bg);
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

.iq-sidebar .iq-sidebar-logo {
  float: none;
  width: 100%;
}

.iq-sidebar #sidebar-scrollbar {
  margin-top: 15px;
}

/*---------------------------------------------------------------------
                             Sidebar Menu
-----------------------------------------------------------------------*/

/* Form */
#sidebar-scrollbar {
  height: 90vh;
}

.scroll-content div.p-3 {
  height: 100px;
}

.iq-sidebar-menu .iq-menu .iq-menu-title {
  display: block;
  color: var(--iq-body-text);
  font-size: 15px;
  position: relative;
  padding: 15px 20px;
  font-weight: 500;
  line-height: 18px;
}

.iq-sidebar-menu {
  position: relative;
}

.iq-sidebar-menu .iq-menu {
  margin: 0 15px;
  padding: 0;
  list-style-type: none;
  text-transform: capitalize;
}

.iq-sidebar-menu .iq-menu li {
  list-style-type: none;
  position: relative;
  white-space: nowrap;
}

.iq-sidebar-menu .iq-menu li a {
  font-size: 14px;
  white-space: nowrap;
  display: block;
  color: var(--iq-icon-color);
  position: relative;
  padding: 10px 20px;
  margin: 4px 0;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
}

.iq-sidebar-menu .iq-menu li li a {
  font-size: 14px;
}

.iq-sidebar-menu .iq-menu li a i {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
  display: inline-block;
  float: left;
}

.iq-sidebar-menu .iq-menu li li a i {
  font-size: 16px;
}

.iq-sidebar-menu .iq-menu li a span {
  white-space: nowrap;
  padding: 0;
  display: inline-block;
  float: left;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transition: none;
  /* font-size: 15px; */
}

.iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  margin-left: auto;
}

.iq-sidebar-menu .iq-menu li a .badge {
  position: absolute;
  right: 15px;
  -webkit-transition: all 0.9s ease-out 0s;
  -moz-transition: all 0.9s ease-out 0s;
  -ms-transition: all 0.9s ease-out 0s;
  -o-transition: all 0.9s ease-out 0s;
  transition: all 0.9s ease-out 0s;
}

.iq-sidebar-menu .iq-menu > li.active {
  border-bottom: none;
  margin-bottom: 10px;
}

.iq-sidebar-menu .iq-menu li a:hover {
  color: var(--iq-primary);
}

.iq-sidebar-menu .iq-menu li.active > a {
  color: var(--iq-white);
  text-decoration: none;
  background: var(--iq-primary);
  text-decoration: none;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-sidebar-menu .iq-menu li a[aria-expanded="true"] {
  color: var(--iq-white);
  /* color: #7a6699; */
  text-decoration: none;
  background: var(--iq-primary);

  transition: none;
  border-radius: 0 8px 0 0;
}

.iq-sidebar-menu .iq-menu li a[aria-expanded="true"] .iq-arrow-right {
  transform: rotate(90deg);
}

.iq-sidebar-menu .iq-menu li .iq-submenu li a:hover,
.iq-sidebar-menu .iq-menu li.active .iq-submenu li > a {
  background: transparent;
}

.iq-sidebar-menu .iq-menu li.active .iq-submenu li.active > a {
  background: var(--iq-light-primary);
  color: var(--iq-primary);
  border-radius: 0;
}

.iq-sidebar-menu .iq-menu li .iq-submenu li > a::before {
  display: none;
}

ul#topbar-data-icon {
  margin: -60px 10px 0;
}

.topbar-menu-icon li a {
  background: var(--iq-card-bg);
  padding: 5px 10px;
  margin: 0 0 0 15px;
  border-radius: 8px;
}

.searchbox-datalink {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--iq-card-bg);
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(120, 146, 141, 0.06);
}

.searchbox-datalink.show-data {
  opacity: 1;
  visibility: visible;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  transform: translateY(100%);
}

/*------------------------*/
.iq-sidebar-menu .iq-menu li ul {
  padding-left: 0px;
}

.iq-sidebar-menu .iq-menu li ul li a {
  color: var(--iq-icon-color);
}

.iq-sidebar-menu .iq-menu li ul a:hover {
  color: var(--iq-primary);
  text-decoration: none;
  background: transparent;
}

.iq-sidebar-menu .iq-menu .iq-submenu {
  background: var(--iq-body-bg);
  border-radius: 0 0 8px 0;
}

.iq-sidebar-menu .iq-menu li ul.iq-submenu-data li > a {
  padding-left: 50px;
}

.iq-sidebar-menu .iq-menu li.active .iq-submenu li a[aria-expanded="true"],
.iq-sidebar-menu .iq-menu li .iq-submenu li a[aria-expanded="true"] {
  background: var(--iq-icon-color);
  border-radius: 0;
  color: var(--iq-white);
}

.iq-sidebar-menu .iq-menu li.active ul.iq-submenu-data li.active > a {
  background: var(--iq-light-primary);
  color: var(--iq-primary);
  border-radius: 0;
}

.iq-sidebar-menu .iq-menu li ul.iq-submenu-data li {
  margin: 0;
}

body.sidebar-main
  .iq-sidebar
  .iq-sidebar-menu
  .iq-menu
  li
  ul.iq-submenu-data
  li
  > a {
  padding-left: 15px;
}

body.sidebar-main
  .iq-sidebar:hover
  .iq-sidebar-menu
  .iq-menu
  li
  ul.iq-submenu-data
  li
  > a {
  padding-left: 50px;
}

/*---------Sidebar Dark blue ---------------*/
.iq-sidebar.sidebar-dark-blue,
.iq-sidebar.sidebar-dark-blue .iq-sidebar-logo {
  background: var(--iq-primary);
  color: var(--iq-white);
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-logo a span {
  color: rgba(255, 255, 255, 0.8);
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu .iq-menu-title {
  color: rgba(255, 255, 255, 0.4);
}

.iq-sidebar.sidebar-dark-blue .iq-menu li > a::before {
  display: none;
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li > a:hover,
.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li.active > a,
.iq-sidebar.sidebar-dark-blue
  .iq-sidebar.sidebar-dark-blue
  .iq-sidebar-menu
  .iq-menu
  li
  a {
  color: var(--iq-white);
  background: rgb(0 0 0 / 10%);
  border-radius: 0 15px 15px 0;
}

.iq-sidebar.sidebar-dark-blue
  .iq-sidebar-menu
  .iq-menu
  li.active
  .iq-submenu
  li.active
  > a {
  color: var(--iq-white);
  background: rgb(0 0 0 / 10%);
}

.iq-sidebar.sidebar-dark-blue .iq-menu li a:hover,
.iq-sidebar.sidebar-dark-blue .iq-menu li.active > a,
.iq-sidebar.sidebar-dark-blue .iq-menu li.menu-open > a {
  color: var(--iq-white);
  background: var(--iq-dark);
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li a {
  color: rgba(255, 255, 255, 0.9);
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li ul li a {
  background: transparent;
  padding-left: 15px;
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li ul {
  padding-left: 0;
  background: var(--iq-primary-dark);
  left: 111%;
  border-radius: 0 15px 15px 0;
}

.iq-sidebar.sidebar-dark-blue .ripple {
  background: rgba(255, 255, 255, 0.1);
}

.iq-sidebar.sidebar-dark-blue .iq-menu-bt {
  background: var(--iq-primary);
}

.iq-sidebar.sidebar-dark-blue .line-menu {
  background: var(--iq-white);
}

.iq-sidebar.sidebar-dark-blue
  .iq-sidebar-menu
  .iq-menu
  li.active
  .iq-submenu
  li:last-child.active
  > a {
  border-radius: 0 15px 15px 0;
}

/*---------------------------------------------------------------------
                             Top Menu
-----------------------------------------------------------------------*/
.iq-top-navbar .iq-navbar-custom .iq-menu-bt {
  display: none;
}

.iq-top-navbar {
  padding: 0;
  min-height: 73px;
  position: fixed;
  top: 0;
  left: auto;
  right: 30px;
  width: calc(90% - 145px) !important;
  display: inline-block;
  z-index: 99;

  margin: 0;
  transition: all 0.45s ease 0s;

  border-radius: 0 0 15px 15px;
}

.iq-navbar-custom {
  background: rgba(255, 255, 255, 0.05);

  border-radius: 8px;
  margin: 20px 0 0;
  -webkit-box-shadow: 0px 2px 8px var(--iq-card-shadow-1),
    0px 4px 16px var(--iq-card-shadow-2);
  -moz-box-shadow: 0px 2px 8px var(--iq-card-shadow-1),
    0px 4px 16px var(--iq-card-shadow-2);
  box-shadow: 0px 2px 8px var(--iq-card-shadow-1),
    0px 4px 16px var(--iq-card-shadow-2);
}

.iq-top-navbar.fixed-header {
  margin: 0;
  top: 20px;
  right: 0;
  width: calc(100% - 260px);
  border-radius: 0;
  transition: all 0.45s ease 0s;
}

.iq-top-navbar .iq-navbar-custom .navbar-breadcrumb {
  display: block;
  margin-left: 30px;
  padding: 10px 0;
}

.iq-top-navbar .breadcrumb .breadcrumb-item.active {
  color: var(--iq-icon-color);
}

.iq-top-navbar .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}

.iq-top-navbar .breadcrumb li {
  list-style-type: none;
  font-size: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\ea5f";
  font-family: "remixicon";
  font-size: 14px;
  vertical-align: sub;
  color: var(--iq-icon-color);
}

.iq-top-navbar .breadcrumb li a,
.iq-top-navbar .breadcrumb li a:hover {
  text-decoration: none;
}

.navbar-list {
  margin: 0;
  padding: 0;
  float: right;
}

.navbar-list li {
  float: left;
  list-style-type: none;
  position: relative;
}

.navbar-list li > .dropdown .dropdown-menu {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.navbar-list li .dropdown-toggle::after {
  display: none;
}

.navbar-list li > a.language-title {
  font-size: 16px;
}

.navbar-list li > a {
  font-size: 18px;
  padding: 0 15px;
  line-height: 73px;
  color: var(--iq-primary);
  display: block;
  min-height: 75px;
}

.navbar-list li:last-child > a {
  padding-right: 20px;
}

.menu-tag {
  position: absolute;
  background: var(--iq-body-text);
  color: var(--iq-white);
  display: inline-block;
  top: 18px;
  left: 18px;
  font-size: 12px;
  border-radius: 90px;
  line-height: normal;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
}

.navbar-list li a:hover {
  text-decoration: none;
  background-color: var(--iq-light-primary);
  color: var(--iq-primary);
}

.navbar-list li > a > img {
  width: 50px;
  height: 50px;
}

.navbar-list li.iq-nav-user {
  display: flex;
  align-items: flex-start;
}

.navbar-list li.iq-nav-user span {
  flex: 1;
  font-size: 14px;
}

.iq-top-navbar .iq-sidebar-logo .top-logo {
  display: none;
}

.iq-breadcrumb {
  display: none;
}

.nav-item span.dots {
  height: 5px;
  width: 5px;
  font-size: 0;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 25px;
  right: 10px;
  animation: shadow-pulse-dots 1s infinite;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.nav-item span.count-mail {
  height: 5px;
  width: 5px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 10px;
  animation: shadow-pulse 1s infinite;
  border-radius: 50%;
}

@keyframes shadow-pulse-dots {
  0% {
    box-shadow: 0 0 0 0px rgba(230, 65, 65, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(230, 65, 65, 0);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 132, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 132, 255, 0);
  }
}

div#lottie-beil,
div#lottie-mail,
div#lottie-fullscreen {
  height: 20px;
  width: 20px;
}

div#lottie-beil svg path,
div#lottie-mail svg path,
div#lottie-fullscreen svg path {
  stroke: var(--iq-primary);
}

.navbar-list li .caption {
  line-height: 16px;
}

.top-nav-bottom {
  margin-left: 260px;
  border-top: 1px solid var(--iq-light-border);
}

.topnav-toolbar li a {
  display: block;
  padding: 10px 15px;
  text-transform: capitalize;
  color: var(--iq-secondary);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.topnav-toolbar li.date-today {
  background: var(--iq-light);
}

.topnav-toolbar li.active a {
  background: var(--iq-color-light);
  color: var(--iq-primary);
}

.topnav-toolbar ul li {
  list-style: none;
  padding: 13px 0;
}

.navbar-list li > .dropdown {
  line-height: 73px;
  cursor: pointer;
}

.iq-search-bar {
  padding: 0 15px;
}

.iq-search-bar .searchbox {
  width: 480px;
  position: relative;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 8px;
  border: none;
  background: var(--iq-body-bg);
}

body.top-tab-horizontal .iq-show .search-box {
  padding: 0;
}

/*---------- Sidebar Logo --------------*/
.iq-sidebar-logo {
  display: none;
  width: 260px;
  padding-top: 38px;
  padding-left: 8px;
  float: left;
  position: relative;
  background: var(--iq-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: transparent;
}

.iq-sidebar-logo a {
  display: flex;
  align-items: flex-start;
}

.iq-sidebar-logo a span {
  margin-left: 10px;
  font-size: 28px;
  line-height: 45px;
  color: var(--iq-body-text);
  text-transform: capitalize;
  flex: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-sidebar-logo a:hover,
.iq-sidebar-logo a:focus {
  text-decoration: none;
}

.iq-sidebar-logo img {
  height: 45px;
}

.iq-top-navbar .iq-navbar-custom .iq-menu-bt-sidebar .iq-menu-bt {
  display: block;
  margin: 0 0 0 20px;
}

body.sidebar-main .iq-menu-bt-sidebar .iq-menu-bt {
  opacity: 1;
}

.iq-menu-bt {
  background: var(--iq-body-bg);
  padding: 12px 7px;
  margin-right: 15px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: none;
}

.wrapper-menu {
  font-size: 30px;
  line-height: 15px;
  color: var(--iq-primary);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.line-menu {
  background-color: var(--iq-primary);
  border-radius: 90px;
  width: 100%;
  height: 2px;
}

.line-menu.half {
  width: 50%;
}

.line-menu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
  transform-origin: right;
}

.open .line-menu.start {
  transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
  transform-origin: left;
}

.open .line-menu.end {
  transform: rotate(-90deg) translateX(-3px);
}

/* 
.hover-circle {
  opacity: 0;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
}

.wrapper-menu.open .hover-circle {
  opacity: 1;
}

.wrapper-menu.open .main-circle {
  opacity: 0;
} */

/*----------------------*/
.iq-top-navbar .iq-sub-dropdown {
  width: 300px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-color: var(--iq-white);
  transition: all 0.3s;
  padding: 0;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04),
    0px 16px 24px rgba(96, 97, 112, 0.16);
  -moz-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04),
    0px 16px 24px rgba(96, 97, 112, 0.16);
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04),
    0px 16px 24px rgba(96, 97, 112, 0.16);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-top-navbar .iq-sub-dropdown .iq-card-body div {
  border-radius: 10px 10px 0 0;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-12-22 15:30:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.iq-show .iq-sub-dropdown {
  display: inline-block;
}

.navbar-list li .iq-sub-dropdown .iq-sub-card {
  font-size: inherit;
  padding: 15px;
  line-height: normal;
  color: inherit;
  border-bottom: 1px solid var(--iq-light-border);
  display: inline-block;
  width: 100%;
}

.iq-top-navbar .search-box {
  display: none;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-color: var(--iq-white);
  transition: all 0.3s;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  border-radius: 8px;
}

.iq-show .search-box {
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.search-box .search-input {
  width: 300px;
  height: 50px;
  padding: 0 1em;
  border: 1px solid var(--iq-light-primary);
  background-color: var(--iq-light-primary);
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.iq-sub-dropdown .iq-card-icon {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
}

ul#topbar-data-icon {
  margin: -30px 10px 0;
}

/*---------------------------------------------------------------------
                              Content Page
-----------------------------------------------------------------------*/
.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 100px 15px 0;
  max-height: 100vh;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-user-dropdown {
  width: 350px;
}

body.sidebar-main .iq-top-navbar {
  width: calc(100% - 140px);
}

body.sidebar-main .iq-top-navbar.fixed-header {
  width: calc(100% - 80px);
}

body.sidebar-main .content-page,
body.sidebar-main .iq-footer {
  margin-left: 80px;
}

body.sidebar-main .iq-sidebar-logo {
  width: 80px;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span,
body.sidebar-main .iq-sidebar-logo a span,
body.sidebar-main .iq-sidebar-menu .iq-menu li a .badge {
  opacity: 0;
  display: none;
  transition: all 0.45s ease 0s;
}

body.sidebar-main .iq-menu-bt {
  opacity: 0;
  display: none;
}

body.sidebar-main .iq-sidebar {
  width: 80px;
}

body.sidebar-main .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  display: none;
}

body.sidebar-main .iq-sidebar:hover,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo {
  width: 260px;
}

body.sidebar-main
  .iq-sidebar:hover
  .iq-sidebar-menu
  .iq-menu
  li
  a
  .iq-arrow-right {
  margin-right: 0;
  display: inline-block;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a span,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo a span,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a .badge,
body.sidebar-main .iq-sidebar:hover .iq-menu-bt {
  opacity: 1;
  display: inline-block;
  transition: all 0.45s ease 0s;
}

/* --------------- */
.iq-card.iq-border-box {
  border-bottom: 4px solid;
}

.right-block .image-absulute.image-absulute-1 {
  position: absolute;
  top: 10px;
  left: auto;
  right: 240px;
}

.right-block .image-absulute.image-absulute-2 {
  position: absolute;
  left: auto;
  right: 330px;
  top: 30px;
}

.iq-right-fixed .iq-card .iq-card-header {
  border-color: rgba(255, 255, 255, 0.2);
}

/*--------------------*/
.iq-card-header-list {
  margin: 0;
  padding: 0;
  float: right;
}

.iq-card-header-list li {
  list-style-type: none;
  float: left;
  display: inline-block;
  font-size: 20px;
  margin: 0 0 0 8px;
}

.iq-card-header-list li a,
.iq-card-header-list li a:hover {
  text-decoration: none;
}

/*-------------- Media Header ---------------*/
.iq-card .iq-card-header.iq-header-media {
  padding: 20px;
}

/*-------------- Content Page ---------------*/
.big-card {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  background: var(--iq-white);
  border: 1px solid var(--iq-light-border);
  padding: 20px;
}

.iq-card-body.relative-background {
  position: relative;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.iq-image-full {
  position: relative;
}

/*---------------------------------------------------------------------
                              Error Page
-----------------------------------------------------------------------*/
.iq-error {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: inline-block;
}

.iq-error h1 {
  font-weight: 900;
  font-size: 16rem;
  line-height: 14rem;
  margin-bottom: 0;
  padding-top: 45px;
  letter-spacing: 15px;
}

.iq-error-img {
  position: static;
  display: inline-block;
  width: 700px;
  margin: 100px 0 50px;
}

.error-500 .iq-error-img {
  width: 100%;
}

/*---------------------------------------------------------------------
                               OWL Carousel
-----------------------------------------------------------------------*/
.owl-carousel .owl-nav {
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 999;
}

.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  left: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
}

.owl-carousel .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  right: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
}

.owl-carousel .owl-nav i {
  font-size: 24px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 42px;
  padding-left: 0px;
  display: inline-block;
  color: var(--iq-white);
  background: #e7e7e7;
  font-weight: normal;
  text-align: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.owl-carousel .owl-nav i:hover {
  background: #4ac4f3;
  color: var(--iq-white);
}

/* Dots */
.owl-carousel .owl-controls .owl-dot {
  margin-top: 20px;
  display: inline-block;
}

.owl-carousel .owl-dots {
  position: relative;
  width: 100%;
  display: inline-block;
  text-indent: inherit;
  text-align: center;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot span {
  background: var(--iq-white);
  display: inline-block;
  border-radius: 30px;
  margin: 0px 5px;
  height: 8px;
  width: 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot:hover,
.owl-carousel .owl-dots .owl-dot.active {
  outline: none;
  border: none;
}

.owl-carousel .owl-dots .owl-dot:hover span {
  background: var(--iq-white);
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: var(--iq-white);
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
}

.iq-example-row .row > .col,
.iq-example-row .row > [class^="col-"] {
  padding-top: 0.75rem;
  color: var(--iq-body-text);
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.iq-example-row-flex-cols .row {
  margin-bottom: 15px;
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.iq-icons-list {
  line-height: 50px;
  margin-bottom: 15px;
  display: inline-block;
}

.iq-icons-list i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  float: left;
  width: 40px;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  display: inline-block;
}

.iq-icons-list .icon {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 50px;
  margin-right: 10px;
  display: inline-block;
}

.iq-icons-list span {
  display: inline-block;
  vertical-align: middle;
}

.iq-card-icon {
  height: 35px;
  width: 35px;
  display: inline-block;
  line-height: 35px;
  text-align: center;
  font-size: 22px;
}

/*---------------------------------------------------------------------
                               Timeline
-----------------------------------------------------------------------*/
.iq-timeline {
  margin: 0 0 0 5px;
  padding: 0;
  width: 100%;
  border-left: 3px solid var(--iq-light-border);
}

.iq-timeline li {
  margin-left: 15px;
  position: relative;
  padding: 15px 15px 0 5px;
  list-style-type: none;
}

.iq-timeline li .timeline-dots {
  position: absolute;
  top: 20px;
  left: -24px;
  border: 3px solid var(--iq-primary);
  border-radius: 90px;
  padding: 5px;
  background: var(--iq-white);
}

/*---------------------------------------------------------------------
                               Team
-----------------------------------------------------------------------*/
.iq-team {
  background: var(--iq-white);
  padding: 15px;
  position: relative;
}

.team-authors-bg {
  position: relative;
  margin-bottom: 50px;
}

.team-authors-bg .avatar-80 {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*------------------------------*/
.iq-team-1 {
  position: relative;
  background: var(--iq-white);
}

.iq-team-1 .iq-team-1-info {
  position: absolute;
  padding: 30px 15px;
  bottom: 0;
  left: 0;
  width: 100%;
}

/*---------------------------------------------------------------------
                           User Add Block
-----------------------------------------------------------------------*/
.user-add-block {
  background: var(--iq-white);
}

.user-add-plus {
  position: absolute;
  right: 30px;
  font-size: 24px;
  bottom: -25px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background: var(--iq-body-text);
  color: var(--iq-white);
  border-radius: 900px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
}

.user-add-plus:hover {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-decoration: none;
}

.user-add-authors {
  position: relative;
}

.user-add-authors:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 132, 255, 0.8);
}

.user-add-authors .user-add-media {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  display: inline-block;
}

.user-add-list {
  padding: 30px;
}

/*---------------------------------------------------------------------
                             Email list
-----------------------------------------------------------------------*/
.iq-email-list .iq-email-ui {
  margin: 0;
  padding: 0;
}

.iq-email-list .iq-email-ui li {
  list-style-type: none;
  font-size: 16px;
  color: var(--iq-icon-color);
  margin-bottom: 3px;
}

.iq-email-list .iq-email-ui li:last-child {
  margin-bottom: 0;
}

.iq-email-list .iq-email-ui li a {
  color: var(--iq-icon-color);
  padding: 0;
  display: inline-block;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-list .iq-email-ui li.active a,
.iq-email-list .iq-email-ui li a:hover {
  text-decoration: none;
  color: var(--iq-primary);
}

.iq-email-list .iq-email-ui li {
  list-style-type: none;
}

.iq-email-list .iq-email-ui li i {
  margin-right: 10px;
}

.iq-email-list .iq-email-ui li .badge {
  float: right;
  font-weight: normal;
  margin-top: 3px;
}

.iq-email-list .iq-email-ui.iq-email-label li {
  margin-bottom: 10px;
}

.iq-email-list .iq-email-ui.iq-email-label li:last-child {
  margin-bottom: 0;
}

/*----------------------------------*/
.iq-email-to-list ul {
  margin: 0;
  padding: 0;
}

.iq-email-to-list ul li {
  list-style-type: none;
  font-size: 20px;
  color: var(--iq-icon-color);
  margin: 0 5px 0 0;
  float: left;
}

.iq-email-to-list ul li:first-child a {
  background: none;
  width: auto;
}

.iq-email-to-list ul li > a {
  color: var(--iq-primary);
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  background: rgba(130, 122, 243, 0.2);
}

.iq-email-to-list ul li a:hover,
.iq-email-to-list ul li.q-list a:hover {
  color: var(--iq-primary);
  text-decoration: none;
}

.iq-email-to-list ul li .custom-control-label::before {
  top: 13px;
  left: -20px;
}

/*----------------------------------*/
.iq-email-search {
  position: relative;
}

.iq-email-search .form-control {
  height: 40px;
  border: 1px solid var(--iq-light-border);
  width: 200px;
}

.iq-email-search .search-link {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 18px;
  line-height: 38px;
  color: var(--iq-primary);
}

.iq-email-search .search-link:hover {
  text-decoration: none;
}

/*----------------------------------*/
.iq-email-listbox .iq-email-sender-list {
  margin: 15px 0 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.iq-email-listbox .iq-email-sender-list > li {
  list-style-type: none;
  position: relative;
  padding: 12px 20px;
  border-top: 1px solid var(--iq-light-border);
  float: left;
  display: inline-block;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-listbox .iq-email-sender-list > li:hover {
  background: var(--iq-white);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(71, 91, 199, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(71, 91, 199, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(71, 91, 199, 0.2);
}

.iq-email-listbox .iq-email-sender-list li a:hover {
  text-decoration: none;
}

.iq-email-listbox .iq-email-sender-list li.iq-unread a {
  color: var(--iq-primary);
  font-weight: 500;
}

.iq-email-listbox .iq-email-sender-list li.iq-unread li a {
  color: var(--iq-primary);
}

.iq-email-sender-info .iq-checkbox-mail,
.iq-email-sender-info .iq-star-toggle {
  display: block;
  float: left;
}

.iq-email-sender-info .iq-checkbox-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
}

.iq-email-sender-info .iq-star-toggle {
  color: var(--iq-icon-color);
  margin-left: 10px;
  font-size: 18px;
}

.iq-email-sender-info .iq-email-title {
  color: var(--iq-icon-color);
  position: absolute;
  top: 0;
  left: 85px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 62px;
}

.iq-email-content {
  position: absolute;
  top: 0;
  left: 300px;
  right: 0;
  bottom: 0;
  /* line-height: 62px; */
  line-height: 90px !important;
}

.iq-email-content .iq-email-date,
.iq-email-content .iq-email-subject {
  position: absolute;
  top: 0;
}

.iq-email-content .iq-email-subject {
  padding-top: 25px;
  color: var(--iq-icon-color);
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iq-email-content .iq-email-date {
  right: 20px;
  width: 100px;
  text-align: right;
  padding-left: 10px;
  color: var(--iq-body-text);
}

.iq-email-sender-list .iq-social-media {
  position: absolute;
  line-height: 90px !important;
  right: -200px;
  top: 0;
  background: var(--iq-white);
  font-size: 18px;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-listbox .iq-email-sender-list li:hover .iq-social-media {
  right: 0;
  top: 15%;
}

/*----------------------------------*/
.iq-inbox-details tr td {
  padding: 5px 10px;
}

.iq-inbox-details tr td:first-child {
  width: 75px;
  color: var(--iq-body-text);
}

.iq-subject-info {
  display: flex;
  align-items: flex-start;
}

.iq-subject-status {
  margin-left: 15px;
  flex: 1;
}

/*---------------------------------------------------------------------
                             Booking list
-----------------------------------------------------------------------*/
.iq-booking-date {
  background: var(--iq-light-border);
  padding: 15px 15px;
  margin: 0 15px 45px;
  outline: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: block;
}

.iq-booking-date:hover {
  text-decoration: none;
}

.iq-booking-date:after {
  content: "";
  position: absolute;
  bottom: 20px;
  left: -8px;
  height: 16px;
  width: 16px;
  background: var(--iq-white);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  opacity: 0;
}

.iq-booking-date:before {
  content: "";
  position: absolute;
  bottom: 20px;
  right: -8px;
  height: 16px;
  width: 16px;
  background: var(--iq-white);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  opacity: 0;
}

.slick-slide.slick-active.slick-center .iq-booking-date {
  background: var(--iq-primary);
  color: var(--iq-white);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 132, 255, 0.4);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0, 132, 255, 0.4);
  box-shadow: 0px 10px 30px 0px rgba(0, 132, 255, 0.4);
}

.slick-slide.slick-active.slick-center .iq-booking-date h3 {
  color: var(--iq-white);
}

.slick-slide.slick-active.slick-center .iq-booking-date:after {
  opacity: 1;
}

.slick-slide.slick-active.slick-center .iq-booking-date:before {
  opacity: 1;
}

.slick-initialized .slick-slide {
  outline: none;
}

.iq-booking-date .iq-booking-dot {
  height: 12px;
  width: 12px;
  margin-bottom: 10px;
  display: inline-block;
  background: var(--iq-white);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

.iq-booking-date h3 {
  line-height: 1.5rem;
  margin-bottom: 0;
}

.iq-booking-date h3 small {
  font-size: 14px;
}

.icon-tag-box span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
}

.iq-booking-offer-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 110%;
}

.iq-booking-offer {
  padding: 60px 100px;
  border-radius: 8px;
  margin-bottom: 30px;
}

/*----------------------------------------------------------------------
                        Chart
 ----------------------------------------------------------------------*/
#sda1-chart,
#sda1-chart1,
#sda1-chart2 {
  min-height: 200px !important;
}

/*-----------------------------------------------------------------
                        Material Card Style1
----------------------------------------------------------------- */
.card-style1 {
  display: flex;
}

.card-style1 img {
  object-fit: cover;
  border-radius: 100px;
  height: 75px;
  margin-right: 10px;
}

.card-style1 .title {
  text-transform: capitalize;
  font-weight: bold;
}

.card-style1 .percentage {
  font-weight: bolder;
  font-size: 1.5rem;
  margin: auto;
}

.card-style1 .percentage span {
  font-weight: normal;
}

/*-----------------------------------------------------------------
                        Material Card Style2
----------------------------------------------------------------- */
.card-style1.card-style2 .iq-details {
  width: 100%;
}

.card-style1.card-style2 .percentage {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-top: -5px;
  margin-left: -50px;
}

/*-----------------------------------------------------------------
                        Circle Progressbar
------------------------------------------------------------------- */
.iq-circle-progressbar .percent {
  font-size: 1.3rem !important;
}

.iq-circle-progressbar svg {
  width: 100px !important;
  height: 100px !important;
}

.iq-circle-progressbar {
  margin: 0 auto;
  width: 100px !important;
  height: 100px !important;
}

/*-----------------------------------------------------------------
                        Tikit Booking List
------------------------------------------------------------------- */
.iq-booking-ab {
  display: inline-block;
}

.iq-booking-ab li {
  line-height: 3rem;
  height: 45px;
  margin-bottom: 1rem;
  position: relative;
}

.iq-booking-ab li.friest-booking-blank {
  margin-bottom: 0;
  height: 40px;
}

.iq-booking-ab li.secondary-booking-blank {
  height: 70px;
}

.iq-booking-ab li.three-booking-blank {
  height: 70px;
}

.iq-booking-no li {
  width: 4.9%;
  text-align: center;
  margin-right: 0 !important;
}

.iq-booking-no li .iq-seat {
  background: var(--iq-light-primary);
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
}

.iq-booking-no li .iq-seat img {
  width: 100%;
}

.iq-booking-no li .iq-seat img.active-seat {
  display: none;
}

.iq-booking-no li .iq-seat.bg-secondary img.default,
.iq-booking-no li .iq-seat.active img.default {
  display: none;
}

.iq-booking-no li .iq-seat.bg-secondary img.active-seat,
.iq-booking-no li .iq-seat.active img.active-seat {
  display: inline-block;
}

.iq-booking-no li .iq-seat.active {
  background: var(--iq-success);
}

.iq-booking-status {
  line-height: 30px;
  display: inline-block;
  margin-right: 30px;
}

.iq-booking-status span {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: inline-block;
  background: var(--iq-light-primary);
  margin-right: 10px;
  margin-top: 6px;
  float: left;
}

/*-----------------------------------------------------------------
                        Right Sidebar Menu
------------------------------------------------------------------- */
#right-sidebar-scrollbar {
  height: 100vh;
}

.iq-sidebar-right-menu {
  position: fixed;
  top: 75px;
  right: -400px;
  width: 360px;
  background: var(--iq-light-border);
  overflow: hidden;
  z-index: 99;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-sidebar-right-menu.film-side {
  right: 0;
}

.iq-sidebar-booking,
.iq-sidebar-payment {
  padding: 30px;
}

.iq-booking-ticket {
  background: var(--iq-white);
  border-radius: 4px;
  padding: 30px 25px 30px;
  position: relative;
}

.iq-booking-ticket:after {
  content: "";
  position: absolute;
  bottom: 100px;
  left: -10px;
  height: 20px;
  width: 20px;
  background: var(--iq-primary);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

.iq-booking-ticket:before {
  content: "";
  position: absolute;
  bottom: 100px;
  right: -10px;
  height: 20px;
  width: 20px;
  background: var(--iq-primary);
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

/*---------------------------------------------------------------------
FAQ
-----------------------------------------------------------------------*/
.iq-accordion.career-style.faq-style .iq-accordion-block .accordion-title span {
  padding-left: 0px;
}

.iq-accordion.career-style .iq-accordion-block {
  margin-bottom: 0px;
}

.iq-accordion.career-style.faq-style .accordion-title:before {
  display: inline-block;
  cursor: pointer;
  content: "\f218";
  font-family: "Ionicons";
  position: absolute;
  top: -8px;
  right: 15px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.iq-accordion.career-style.faq-style .iq-accordion-block .accordion-details p {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.iq-accordion.career-style .iq-accordion-block .accordion-title span {
  font-size: 16px;
  font-family: "Muli", sans-serif;
  display: table-cell;
  color: var(--iq-dark);
}

.iq-accordion.career-style.faq-style
  .iq-accordion-block.accordion-active
  .accordion-title:before {
  content: "\f209";
  display: inline-block;
}

.iq-accordion.career-style.faq-style .active-faq .row {
  background: transparent;
  padding: 10px 0px;
}

.iq-accordion.career-style.faq-style .iq-accordion-block {
  padding: 10px 5px;
  border-radius: 20px;
}

.iq-accordion.career-style.faq-style
  .iq-accordion-block.accordion-active
  .accordion-title:before,
.iq-accordion.career-style.faq-style .accordion-title:before {
  border-radius: 90px;
}

/*---------------------------------------------------------------------
Table
-----------------------------------------------------------------------*/
table tr th img {
  width: 35px;
  height: 35px;
  border-radius: 90px;
}

.progress-animate {
  animation: progress-bar-stripes 1s linear infinite;
}

img.summary-image-top {
  width: 35px;
}

.iq-subject-info img {
  width: 80px;
}

/*---------------------------------------------------------------------
Animation Card
-----------------------------------------------------------------------*/
.animation-card {
  position: relative;
}

.animation-card .an-img {
  margin-top: -60px;
  text-align: right;
  display: inline-block;
  width: 100%;
}

.animation-card .an-img .bodymovin {
  width: 162%;
  margin-bottom: -57px;
  margin-left: -50px;
}

.animation-card .an-text {
  position: absolute;
  width: 180px;
  display: inline-block;
  top: 75px;
  left: 30px;
}

.animation-card .an-text h2 {
  margin-top: 0;
}

/*------------*/
.an-img-two {
  width: 700px;
  position: absolute;
  right: -35%;
  top: -175px;
  z-index: -1;
}

.iq-card.bg-primary.sb-top-banner-card {
  z-index: 1;
  position: relative;
}

.bodymovin {
  z-index: -1;
}

/*------------*/
.offer-an-img {
  position: absolute;
  top: -183px;
  width: 695px;
  left: -100px;
}

/*---------------------------------------------------------------------
Footer
-----------------------------------------------------------------------*/
.iq-footer {
  padding: 15px;
  background: var(--iq-card-bg);
  margin-left: 260px;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  -moz-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-footer ul li a {
  color: var(--iq-icon-color);
}

.iq-footer ul li a:hover {
  color: var(--iq-primary);
  text-decoration: none;
}

.bg-banner-image.style-three {
  height: 150px;
  /* background: url("../images/bg-03.jpg"); */
}

.iq-mt--70 {
  margin-top: -70px;
}

.banner-main-text {
  position: absolute;
  left: 15%;
  bottom: 13%;
}

.nav.nav-pills.style-three .nav-link.active:before,
.nav.nav-pills.style-three .nav-link:hover:before {
  display: inline-block;
  width: 100%;
  height: 3px;
  background: var(--iq-primary);
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}

.nav.nav-pills.style-three .nav-link:before {
  width: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  height: 3px;
  background: var(--iq-primary);
  content: "";
  position: absolute;
  right: 0;
  bottom: 0px;
}

.iq-card-body.banner-box {
  padding-bottom: 0;
}

.nav.nav-pills.style-three {
  margin-top: 10px;
}

.nav.nav-pills.style-three a:last-child {
  margin-right: 0px;
}

.nav.nav-pills.style-three a {
  background: transparent;
  position: relative;
  margin-right: 30px;
  padding: 10px 0px;
}

/*---------------------------------------------------------------------
Map Profile
-----------------------------------------------------------------------*/
.dash-tracking-icon {
  height: 80px;
}

.map-profile-card {
  margin-right: 30px;
  border: 1px solid var(--iq-light-border);
  margin-bottom: 15px;
}

.track {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--iq-light-border);
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4a5b67;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--iq-dark);
  border-radius: 20px;
}

/*---------------------------------------------------------------------
Mail Inbox
-----------------------------------------------------------------------*/
.email-app-details.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.email-app-details {
  position: absolute;
  display: block;
  z-index: 6;
  visibility: hidden;
  opacity: 0;
  top: 0;
  width: calc(100% - 15px);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 100%;
  left: auto;
  right: 0;
  background-color: var(--iq-white);
  border-radius: 30px;
  -webkit-border-radius: 30px;
}

.attegement ul {
  padding: 0;
  list-style: none;
}

.send-panel a {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

#compose-email-popup {
  position: fixed;
  max-width: 700px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  padding: 0 !important;
  max-height: 555px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#compose-email-popup .iq-card {
  border-radius: 30px 30px 0 0;
}

button.close-popup {
  background: transparent;
  color: var(--iq-black);
  border: none;
  font-size: 20px;
  line-height: 20px;
}

.compose-bottom {
  border-top: 1px solid var(--iq-icon-color);
}

.select2-container--default .select2-selection--multiple {
  border-color: var(--iq-dark-border);
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  color: var(--iq-body-text);
  border-color: var(--iq-dark-border);
  box-shadow: none;
}

/* colors changes in radio button, checkbox, switch */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox.custom-checkbox-color input.bg-primary ~ label::before,
.custom-checkbox.custom-checkbox-color-check
  input:checked.bg-primary
  ~ label::before,
.custom-radio.custom-radio-color input.bg-primary ~ label::before,
.custom-radio.custom-radio-color-checked
  input:checked.bg-primary
  ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-primary ~ label::before {
  background-color: var(--iq-primary) !important;
  border-color: var(--iq-primary) !important;
}

.custom-checkbox.custom-checkbox-color input.bg-success ~ label::before,
.custom-checkbox.custom-checkbox-color-check
  input:checked.bg-success
  ~ label::before,
.custom-radio.custom-radio-color input.bg-success ~ label::before,
.custom-radio.custom-radio-color-checked
  input:checked.bg-success
  ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-success ~ label::before {
  background-color: var(--iq-success) !important;
  border-color: var(--iq-success) !important;
}

.custom-checkbox.custom-checkbox-color input.bg-danger ~ label::before,
.custom-checkbox.custom-checkbox-color-check
  input:checked.bg-danger
  ~ label::before,
.custom-radio.custom-radio-color input.bg-danger ~ label::before,
.custom-radio.custom-radio-color-checked
  input:checked.bg-danger
  ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-danger ~ label::before {
  background-color: var(--iq-danger) !important;
  border-color: var(--iq-danger) !important;
}

.custom-checkbox.custom-checkbox-color input.bg-warning ~ label::before,
.custom-checkbox.custom-checkbox-color-check
  input:checked.bg-warning
  ~ label::before,
.custom-radio.custom-radio-color input.bg-warning ~ label::before,
.custom-radio.custom-radio-color-checked
  input:checked.bg-warning
  ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-warning ~ label::before {
  background-color: var(--iq-warning) !important;
  border-color: var(--iq-warning) !important;
}

.custom-checkbox.custom-checkbox-color input.bg-dark ~ label::before,
.custom-checkbox.custom-checkbox-color-check
  input:checked.bg-dark
  ~ label::before,
.custom-radio.custom-radio-color input.bg-dark ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-dark ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-dark ~ label::before {
  background-color: var(--iq-body-text) !important;
  border-color: var(--iq-body-text) !important;
}

.custom-checkbox.custom-checkbox-color input.bg-info ~ label::before,
.custom-checkbox.custom-checkbox-color-check
  input:checked.bg-info
  ~ label::before,
.custom-radio.custom-radio-color input.bg-info ~ label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-info ~ label::before,
.custom-switch.custom-switch-color input:checked.bg-info ~ label::before {
  background-color: var(--iq-info) !important;
  border-color: var(--iq-info) !important;
}

/* checkbox with icon */
.custom-checkbox.checkbox-icon label i {
  position: absolute;
  top: 6px;
  left: -21px;
  font-size: 10px;
  transform: scale(0);
  -webkit-transform: scale(0);
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

.custom-checkbox.checkbox-icon input:checked ~ label i {
  transform: scale(1);
  -webkit-transform: scale(1);
  color: var(--iq-white);
}

.custom-checkbox.checkbox-icon input:checked ~ label::after {
  display: none;
}

/* switch with icon */
.custom-switch.custom-switch-icon label .switch-icon-left,
.custom-switch.custom-switch-icon label .switch-icon-right,
.custom-switch.custom-switch-text label .switch-icon-left,
.custom-switch.custom-switch-text label .switch-icon-right {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -3px;
}

.custom-switch.custom-switch-icon label .switch-icon-left i,
.custom-switch.custom-switch-icon label .switch-icon-right i {
  font-size: 10px;
}

.custom-switch.custom-switch-icon label .switch-icon-left,
.custom-switch.custom-switch-text label .switch-icon-left {
  left: 8px;
  right: auto;
  color: var(--iq-white);
}

.custom-switch.custom-switch-icon label .switch-icon-right,
.custom-switch.custom-switch-text label .switch-icon-right {
  right: 5px;
  left: auto;
}

.custom-switch.custom-switch-icon,
.custom-switch.custom-switch-text {
  padding-left: 0;
}

.custom-switch.custom-switch-icon .custom-control-label {
  height: 20px;
  width: 42px;
  padding: 0;
}

.custom-switch.custom-switch-text .custom-control-label {
  width: 46px;
}

.custom-switch.custom-switch-icon .custom-control-label::before,
.custom-switch.custom-switch-text .custom-control-label::before {
  height: 20px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  user-select: none;
  top: 0;
  left: 1px;
  width: 2.75rem;
}

.custom-switch.custom-switch-text .custom-control-label::before {
  width: 100%;
}

.custom-switch.custom-switch-icon .custom-control-label:after,
.custom-switch.custom-switch-text .custom-control-label:after {
  position: absolute;
  top: 1px;
  left: 2px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  user-select: none;
  height: 18px;
  width: 18px;
}

.custom-switch.custom-switch-icon
  .custom-control-input:checked
  ~ .custom-control-label::after {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}

.custom-switch.custom-switch-text
  .custom-control-input:checked
  ~ .custom-control-label::after {
  -webkit-transform: translateX(1.6rem);
  -ms-transform: translateX(1.6rem);
  transform: translateX(1.6rem);
}

.custom-switch-inner {
  display: block;
  text-align: center;
}

.custom-switch.custom-switch-text input:checked ~ label::before {
  color: var(--iq-white);
  content: attr(data-on-label);
  text-align: left;
}

.custom-switch.custom-switch-text label::before {
  color: var(--iq-black);
  content: attr(data-off-label);
  width: 2.85rem;
  height: 20px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
}

.custom-switch.custom-switch-text label:after {
  position: absolute;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  user-select: none;
  height: 16px;
  width: 16px;
}

.custom-switch.custom-switch-text
  input:checked
  ~ label.custom-control-label::after {
  transform: translateX(1.6rem);
  -webkit-transform: translateX(1.6rem);
}

.form-label-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  padding: 0.6rem;
  color: var(--iq-body-text);
  font-size: 0.85rem;
  opacity: 0;
}

.counter-value {
  color: var(--iq-white);
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

.vector-map.h-500 {
  width: 100%;
  height: 500px;
}

/*---------------------------------------------------------------------
slick Arrow
-----------------------------------------------------------------------*/
.slick-arrow.left {
  left: 0;
  top: 0;
}

.slick-arrow.right {
  right: 0;
  top: 0;
}

.slick-arrow {
  background: var(--iq-light-primary);
  padding: 11px;
  font-size: 24px;
  position: absolute;
  line-height: 90px;
  z-index: 9;
}

.slick-arrow:hover {
  text-decoration: none;
}

/*---------------------------------------------------------------------
Calender
-----------------------------------------------------------------------*/
.fc-unthemed .fc-toolbar .fc-button {
  background: rgba(130, 122, 243, 0.2);
  color: var(--iq-primary);
  border: none;
  text-transform: capitalize;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  color: var(--iq-white);
  background-color: var(--iq-primary);
}

.job-classification li,
.today-schedule li {
  list-style-type: none;
  margin-bottom: 10px;
}

.job-classification li > i,
.today-schedule li i {
  margin-right: 10px;
  border-radius: 50%;
  color: var(--iq-white);
}

.schedule-text span {
  display: block;
}

.calender-small .flatpickr-calendar.animate.inline {
  width: 100%;
  box-shadow: none;
}

.calender-small .dayContainer {
  width: 100%;
  min-width: auto;
}

.calender-small .flatpickr-days {
  width: 100%;
}

.calender-small .flatpickr-day.today {
  color: var(--iq-white);
  border-color: var(--iq-primary);
  background: var(--iq-primary);
}

.calender-small .flatpickr-day.today:hover,
.calender-small .flatpickr-day.today:focus {
  color: var(--iq-white);
  border-color: var(--iq-primary);
  background: var(--iq-success);
}

.calender-small .flatpickr-months {
  margin-bottom: 10px;
}

.calender-small .flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 16px;
}

.calender-small .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: transparent;
  color: var(--iq-primary);
}

.calender-small .flatpickr-current-month input.cur-year {
  font-size: 16px;
}

.calender-small .numInputWrapper:hover {
  background: transparent;
}

.calender-small .flatpickr-months .flatpickr-prev-month,
.calender-small .flatpickr-months .flatpickr-next-month {
  padding: 7px 10px;
}

.calender-small .flatpickr-day.selected,
.calender-small .flatpickr-day.startRange,
.calender-small .flatpickr-day.endRange,
.calender-small .flatpickr-day.selected.inRange,
.calender-small .flatpickr-day.startRange.inRange,
.calender-small .flatpickr-day.endRange.inRange,
.calender-small .flatpickr-day.selected:focus,
.calender-small .flatpickr-day.startRange:focus,
.calender-small .flatpickr-day.endRange:focus,
.calender-small .flatpickr-day.selected:hover,
.calender-small .flatpickr-day.startRange:hover,
.calender-small .flatpickr-day.endRange:hover,
.calender-small .flatpickr-day.selected.prevMonthDay,
.calender-small .flatpickr-day.startRange.prevMonthDay,
.calender-small .flatpickr-day.endRange.prevMonthDay,
.calender-small .flatpickr-day.selected.nextMonthDay,
.calender-small .flatpickr-day.startRange.nextMonthDay,
.calender-small .flatpickr-day.endRange.nextMonthDay {
  color: var(--iq-white);
  border-color: var(--iq-primary);
  background: var(--iq-primary);
}

.calender-small .flatpickr-day.inRange,
.calender-small .flatpickr-day.prevMonthDay.inRange,
.calender-small .flatpickr-day.nextMonthDay.inRange,
.calender-small .flatpickr-day.today.inRange,
.calender-small .flatpickr-day.prevMonthDay.today.inRange,
.calender-small .flatpickr-day.nextMonthDay.today.inRange,
.calender-small .flatpickr-day:hover,
.calender-small .flatpickr-day.prevMonthDay:hover,
.calender-small .flatpickr-day.nextMonthDay:hover,
.calender-small .flatpickr-day:focus,
.calender-small .flatpickr-day.prevMonthDay:focus,
.calender-small .flatpickr-day.nextMonthDay:focus {
  background: var(--iq-color-light);
  color: var(--iq-primary);
  border-color: transparent;
}

.smaill-calender-home .flatpickr-calendar.animate.inline {
  margin: 0 auto;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 8px;
}

.smaill-calender-home .flatpickr-day.selected,
.smaill-calender-home .flatpickr-day.startRange,
.smaill-calender-home .flatpickr-day.endRange,
.smaill-calender-home .flatpickr-day.selected.inRange,
.smaill-calender-home .flatpickr-day.startRange.inRange,
.smaill-calender-home .flatpickr-day.endRange.inRange,
.smaill-calender-home .flatpickr-day.selected:focus,
.smaill-calender-home .flatpickr-day.startRange:focus,
.smaill-calender-home .flatpickr-day.endRange:focus,
.smaill-calender-home .flatpickr-day.selected:hover,
.smaill-calender-home .flatpickr-day.startRange:hover,
.smaill-calender-home .flatpickr-day.endRange:hover,
.smaill-calender-home .flatpickr-day.selected.prevMonthDay,
.smaill-calender-home .flatpickr-day.startRange.prevMonthDay,
.smaill-calender-home .flatpickr-day.endRange.prevMonthDay,
.smaill-calender-home .flatpickr-day.selected.nextMonthDay,
.smaill-calender-home .flatpickr-day.startRange.nextMonthDay,
.smaill-calender-home .flatpickr-day.endRange.nextMonthDay {
  color: var(--iq-white);
  text-decoration: none;
  background: var(--iq-primary);
  border-color: var(--iq-primary);
}

.smaill-calender-home .flatpickr-days,
.smaill-calender-home .dayContainer {
  width: 100%;
}

.smaill-calender-home .flatpickr-innerContainer {
  justify-content: space-around;
}

/*---------------------------------------------------------------------
 Wizard
-----------------------------------------------------------------------*/
#form-wizard1,
#form-wizard2,
#form-wizard3 {
  position: relative;
}

#form-wizard1 input[type="file"] {
  line-height: 27px;
}

#form-wizard1 fieldset:not(:first-of-type) {
  display: none;
}

.card {
  z-index: 0;
  border: none;
  position: relative;
}

.steps {
  font-size: 20px;
  color: var(--iq-icon-color);
  font-weight: normal;
  text-align: right;
}

#top-tab-list {
  margin: 0 -10px 60px;
  overflow: hidden;
  color: var(--iq-icon-color);
}

#top-tab-list .active {
  color: var(--iq-primary);
}

#top-tab-list li {
  list-style-type: none;
  font-size: 18px;
  width: 25%;
  float: left;
  text-align: left;
  position: relative;
  font-weight: 400;
}

#top-tab-list li i {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  color: var(--iq-white);
  background: var(--iq-primary);
}

#top-tab-list li.active a {
  color: var(--iq-white);
  background: var(--iq-primary);
}

#top-tab-list li.active.done a {
  background: var(--iq-success);
}

#top-tab-list li.active.done i {
  color: var(--iq-success);
}

#top-tab-list li#confirm.active a {
  background: var(--iq-success);
}

#top-tab-list li#confirm.active i {
  color: var(--iq-success);
}

#top-tab-list li a {
  background: var(--iq-color-light);
  color: var(--iq-primary);
  display: block;
  padding: 15px;
  margin: 0 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  text-decoration: none;
}

#top-tab-list li.active i {
  background-color: var(--iq-white);
  color: var(--iq-primary);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

/* validate */
.stepwizard-row {
  display: block;
  margin: 0 -20px;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.setup-content {
  margin-top: 60px;
}

.wizard-step {
  font-size: 18px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  text-align: center;
}

.stepwizard-row .wizard-step a.btn {
  background: var(--iq-white);
  color: var(--iq-body-text);
  font-size: 18px;
  font-weight: 400;
  display: block;
  padding: 50px 15px;
  margin: 10px 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  text-decoration: none;
  border: 1px solid var(--iq-light-border);
  font-family: "Muli", sans-serif;
}

.stepwizard-row .wizard-step i {
  display: block;
  text-align: center;
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 25px;
  border-radius: 50%;
  margin: 0 auto 20px;
  color: var(--iq-white);
  background: var(--iq-primary);
}

.stepwizard-row #user.active a {
  background: var(--iq-light-primary);
  border-color: var(--iq-primary);
}

.stepwizard-row #document.active a {
  background: var(--iq-light-danger);
  border-color: var(--iq-danger);
}

.stepwizard-row #bank.active a {
  background: var(--iq-light-success);
  border-color: var(--iq-success);
}

.stepwizard-row #confirm.active a {
  background: var(--iq-light-warning);
  border-color: var(--iq-warning);
}

.stepwizard-row .wizard-step#user i {
  background: var(--iq-color-light);
  color: var(--iq-primary);
}

.stepwizard-row .wizard-step#document i {
  background: var(--iq-light-danger);
  color: var(--iq-danger);
}

.stepwizard-row .wizard-step#bank i {
  background: var(--iq-light-success);
  color: var(--iq-success);
}

.stepwizard-row .wizard-step#confirm i {
  background: var(--iq-light-warning);
  color: var(--iq-warning);
}

.stepwizard-row .wizard-step.active i {
  background-color: var(--iq-white) !important;
}

.stepwizard-row .wizard-step#document.active i {
  background: var(--iq-light-danger);
  color: var(--iq-danger);
}

.stepwizard-row .wizard-step#bank.active i {
  background: var(--iq-light-success);
  color: var(--iq-success);
}

.stepwizard-row .wizard-step#confirm.active i {
  background: var(--iq-light-warning);
  color: var(--iq-warning);
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: var(--iq-danger);
}

.has-error .form-control {
  border: 1px solid var(--iq-danger);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* vertical */
#form-wizard3 fieldset:not(:first-of-type) {
  display: none;
}

#top-tabbar-vertical {
  overflow: hidden;
  color: var(--iq-icon-color);
  margin: 0 -20px 60px;
}

#top-tabbar-vertical .active {
  color: var(--iq-primary);
}

#top-tabbar-vertical li {
  list-style-type: none;
  font-size: 18px;
  width: 100%;
  float: left;
  position: relative;
  font-weight: 400;
}

#top-tabbar-vertical li i {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 20px 0 0;
}

#top-tabbar-vertical li#personal i {
  background: var(--iq-color-light);
  color: var(--iq-primary);
}

#top-tabbar-vertical li#contact i {
  background: var(--iq-light-danger);
  color: var(--iq-danger);
}

#top-tabbar-vertical li#official i {
  background: var(--iq-light-success);
  color: var(--iq-success);
}

#top-tabbar-vertical li#payment i {
  background: var(--iq-light-warning);
  color: var(--iq-warning);
}

#top-tabbar-vertical li.active a {
  border-color: transparent;
}

#top-tabbar-vertical li a {
  background: var(--iq-white);
  color: var(--iq-black);
  display: block;
  padding: 20px 15px;
  margin: 10px 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  text-decoration: none;
  border: 1px solid var(--iq-light-border);
}

#top-tabbar-vertical li.active i {
  background-color: var(--iq-white);
  color: var(--iq-primary);
}

#top-tabbar-vertical #personal.active a {
  background: var(--iq-light-primary);
}

#top-tabbar-vertical #contact.active a {
  background: var(--iq-light-danger);
}

#top-tabbar-vertical #official.active a {
  background: var(--iq-light-success);
}

#top-tabbar-vertical #payment.active a {
  background: var(--iq-light-warning);
}

#top-tabbar-vertical li.active i {
  background: var(--iq-white) !important;
}

/*---------------------------------------------------------------------
Chat
-----------------------------------------------------------------------*/
.chat-page img {
  border-radius: 30px;
}

#chat-search {
  padding-left: 40px;
}

.form-group.chat-search-data > i {
  position: absolute;
  top: 8px;
  left: 15px;
  color: var(--iq-primary);
  font-size: 16px;
}

.chat-sidebar-name {
  width: 100%;
}

.chat-msg-counter {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  line-height: 22px;
  margin: 0 auto;
  font-size: 10px;
}

.chat-meta span {
  font-size: 10px;
}

span.avatar-status {
  position: absolute;
  bottom: -5px;
  top: auto;
  left: auto;
  right: 0;
}

.iq-chat-ui li a {
  text-decoration: none;
  display: block;
  padding: 20px 15px;
  border-bottom: 1px solid var(--iq-light-border);
}

.iq-chat-ui li a.active {
  border-color: transparent;
}

.nav-pills li a.active {
  color: var(--iq-primary);
  background-color: var(--iq-color-light);
}

.iq-chat-ui li:last-child a {
  border: none;
}

.iq-chat-ui li:nth-child(2) a,
.iq-chat-ui li:nth-child(6) a {
  border: none;
}

.chat-sidebar-channel {
  overflow-y: scroll;
  overflow-x: hidden;
  /*height: 450px;*/
  height: calc(100vh - 19rem);
  margin-bottom: 15px;
}

.chat-data {
  /* background: url(../images/page-img/100.jpg) no-repeat scroll center center; */
  background-size: cover;
}

.chat-header-icons .dropdown-toggle:empty::after {
  display: none;
}

.chat-header-icons > a,
.chat-header-icons > span {
  width: 40px;
  height: 40px;
  font-size: 20px;
  margin: 0 5px 0 0;
  color: var(--iq-primary);
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.chat-content {
  position: relative;
  height: calc(100vh - 17rem);
  text-align: center;
  padding: 1.4rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-user {
  float: right;
  text-align: center;
}

.chat-user span.chat-time {
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.chat-detail {
  overflow: hidden;
}

.chat-message {
  position: relative;
  float: right;
  text-align: right;
  padding: 0.75rem 1rem;
  margin: 0 1.33rem 1.33rem 0.3rem;
  clear: both;
  word-break: break-word;
  color: var(--iq-white);
  background: var(--iq-primary);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.6);
}

.chat-message > p {
  margin: 0;
}

.chat-left .chat-message::after {
  right: auto;
  left: -7px;
  border-width: 5px 7px 5px 0;
  border-color: transparent var(--iq-white) transparent transparent;
}

.chat-content .chat-left .chat-message {
  text-align: left;
  float: left;
  margin: 0 0.3rem 1.8rem 1.3rem;
  color: var(--iq-body-text);
  background-color: var(--iq-white);
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
}

.chat-sidebar-channel .avatar,
.avatar {
  position: relative;
}

.chat-start {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 10rem);
  flex-direction: column;
}

span.iq-start-icon {
  font-size: 50px;
  background: var(--iq-white);
  width: 100px;
  text-align: center;
  border-radius: 50%;
}

.chat-attagement {
  font-size: 20px;
}

.chat-attagement a {
  color: var(--iq-icon-color);
}

.chat-detail .chat-message .chat-time {
  position: absolute;
  bottom: -20px;
  right: 0;
  color: var(--iq-body-text);
  font-size: 0.8rem;
  white-space: nowrap;
}

.chat-left .chat-user {
  float: left;
}

.chat-content .chat-body .chat-message p {
  margin-bottom: 0;
}

.chat-data .tab-content {
  position: relative;
}

.chat-searchbar {
  position: relative;
}

.chat-user-detail-popup {
  position: absolute;
  top: 0;
  width: 360px;
  background: var(--iq-white);
  right: 0;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(110%);
  -webkit-transform: translateX(110%);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  border-radius: 5px;
  visibility: hidden;
}

.chat-user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
}

.chatuser-detail .title {
  font-weight: 500;
}

.chatuser-detail {
  padding: 0 35px;
}

.user-profile button.close-popup {
  display: block;
  font-size: 24px;
}

ul.user-status {
  list-style: none;
}

#user-detail-popup {
  position: absolute;
  top: 0;
  width: 310px;
  background: var(--iq-white);
  right: 0;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(-90%);
  -webkit-transform: translateX(-90%);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  border-radius: 5px;
  visibility: hidden;
}

#user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
}

#user-detail-popup .user-profile button.close-popup {
  text-align: right;
  width: 100%;
}

.chat-sidebar-channel h5 {
  border-bottom: 1px solid var(--iq-light-border);
  padding-bottom: 10px;
}

.chat-sidebar-name h6 {
  font-size: 1.2em;
}

.chat-sidebar-name span {
  color: var(--iq-icon-color);
}

button.close-btn-res {
  display: none;
}

div#sidebar-toggle {
  display: none;
}

/*---------------------------------------------------------------------
Profile Page
-----------------------------------------------------------------------*/
.profile-img img {
  /* border-radius: 50%;
  -webkit-border-radius: 50%; */
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.cover-container {
  position: relative;
  opacity: 0.8;
}

ul.header-nav {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 20px;
}

ul.header-nav li {
  list-style: none;
}

ul.header-nav li a {
  display: block;
  list-style: none;
  height: 40px;
  width: 40px;
  background: var(--iq-white);
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: var(--iq-primary);
  font-size: 18px;
  margin: 0 15px 0 0;
}

.profile-img {
  margin-top: -160px;
}

.profile-feed-items li {
  list-style: none;
}

.profile-feed-items li:last-child a {
  margin: 0;
}

.profile-feed-items li a {
  text-decoration: none;
  text-transform: capitalize;
  color: var(--iq-primary);
  background-color: var(--iq-light-primary);
  margin: 0 20px 0 0;
}

.nav-pills.profile-feed-items li a.active {
  color: var(--iq-white);
  background: var(--iq-primary);
}

.news-icon {
  font-size: 18px;
  margin-right: 20px;
}

ul.profile-img-gallary {
  list-style: none;
  margin: 0 -1px 0 -4px !important;
}

.twit-feed p span i {
  background: var(--iq-primary);
  color: var(--iq-white);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  font-size: 10px;
}

.twit-feed .twit-meta-tag {
  color: var(--iq-primary);
}

.twit-feed .media-support-user-img img {
  height: 50px;
}

ul.media-story img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px;
}

ul.media-story li.active img {
  border-color: var(--iq-primary);
}

.add-suggestion {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  color: var(--iq-primary);
}

.suggestions-lists .btn {
  display: block;
}

.feather-icon a,
.message-icon a {
  color: var(--iq-icon-color);
  font-size: 18px;
  font-weight: 300;
}

ul.post-comments {
  list-style: none;
}

.comment-activity > a {
  margin-right: 10px;
  text-transform: capitalize;
  color: var(--iq-primary);
  text-decoration: none;
}

form.comment-text {
  position: relative;
}

.comment-attagement {
  position: absolute;
  left: auto;
  right: 0;
  font-size: 20px;
}

.comment-attagement a {
  color: var(--iq-icon-color);
}

#profile-activity .iq-timeline li {
  margin-bottom: 15px;
}

.about-info a,
div#profile-profile a {
  color: var(--iq-icon-color);
}

.twit-date a {
  color: var(--iq-icon-color);
}

/*---------------------------------------------------------------------
Profile Edit 
-----------------------------------------------------------------------*/
.profile-pic {
  max-width: 100%;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.profile-img-edit {
  position: relative;
  display: inline-block;
  height: 150px;
  width: 150px;
}

.file-upload {
  display: none;
}

.p-image {
  position: absolute;
  top: auto;
  right: 8px;
  bottom: 10px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: var(--iq-primary);
  color: var(--iq-white);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.upload-button {
  /* font-size: 1.5em; */
}

.iq-edit-profile .nav-link {
  padding: 20px 15px;
  border-radius: 0;
  color: var(--iq-icon-color);
  text-align: center;
  font-size: 16px;
  border-left: 1px solid var(--iq-light-border);
}

.iq-edit-profile.nav-pills .nav-link.active,
.iq-edit-profile.nav-pills .show > .nav-link {
  color: var(--iq-white);
  background: var(--iq-primary);
}

ul.iq-edit-profile li:first-child a {
  border-radius: 8px 0 0 15px;
  border-left: none;
}

ul.iq-edit-profile li:last-child a {
  border-radius: 0 15px 15px 0;
}

/*---------------------------------------------------------------------
Todo Page
-----------------------------------------------------------------------*/
.iq-todo-page .search-link,
.iq-todo-right .search-link {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 18px;
  line-height: 43px;
  color: var(--iq-icon-color);
}

.iq-todo-page .todo-search,
.iq-todo-right .todo-search {
  padding-left: 35px;
}

.add-new-project > a {
  padding: 0 15px;
  color: var(--iq-icon-color);
  text-transform: capitalize;
  text-decoration: none;
}

.notification-icon > span {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  line-height: 13px;
  font-size: 8px;
  text-align: center;
  position: absolute;
  top: -5px;
  left: auto;
  right: -8px;
}

.todo-date {
  font-size: 16px;
}

.iq-todo-page ul {
  list-style: none;
}

.iq-todo-page ul li a {
  color: var(--iq-icon-color);
  text-decoration: none;
}

.iq-todo-page ul ul li a {
  padding-left: 25px;
}

.iq-todo-page ul > li > a {
  padding: 10px 15px;
  display: block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.iq-todo-page ul > li[aria-expanded="true"] > a {
  color: var(--iq-primary);
  background: var(--iq-color-light);
}

.iq-todo-page ul > li li:hover a,
.iq-todo-page ul > li li.active a {
  color: var(--iq-primary);
  background: var(--iq-color-light);
}

ul.todo-task-lists li:hover {
  background: var(--iq-color-light);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

ul.todo-task-lists li.active-task {
  background: var(--iq-color-light);
}

.media-support-info p {
  line-height: normal;
}

.todo-task-list .sub-task {
  display: none;
}

.todo-task-list .sub-task.show {
  display: block;
}

/*---------------------------------------------------------------------
Privacy Policy Page
-----------------------------------------------------------------------*/
.privacy-status {
}

.input-group .input-group-text {
  background: var(--iq-light);
  border: 0px solid var(--iq-dark-border);
}

.data-privacy {
  margin: 30px 0;
}

/*---------------------------------------------------------------------
User List Page
-----------------------------------------------------------------------*/
.list-user-action a {
  font-size: 16px;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  margin: 0 3px;
  display: inline-block;
  border-radius: 5px;
}

#user-list-table.table td {
  vertical-align: middle;
}

.user-list-files a {
  padding: 10px 15px;
  margin: 0 0 0 10px;
  border-radius: 8px;
  line-height: normal;
}

.table-responsive #exampleInputSearch {
  width: auto;
}

.fieldlabels {
  color: var(--iq-dark) !important;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: var(--iq-dark);
  padding-left: 0px;
  margin-top: 30px;
}

#progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 33.33%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #step1:before {
  content: "1";
  color: var(--iq-white);
}

#progressbar #step2:before {
  content: "2";
  color: var(--iq-white);
}

#progressbar #step3:before {
  content: "3";
  color: var(--iq-white);
}

#progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  background: var(--iq-dark);
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--iq-dark);
  position: absolute;
  left: 0;
  top: 21px;
  z-index: -1;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar li:nth-child(2):after {
  left: -50%;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: rgba(130, 122, 243, 1);
  border-color: var(--iq-primary);
}

#progressbar li.done:before,
#progressbar li.done:after {
  background: rgba(130, 122, 243, 1);
  border-color: var(--iq-primary);
}

.card-block {
  display: none;
}

.card-block.show {
  display: block;
}

/*---------------------------------------------------------------------
Add User Page
-----------------------------------------------------------------------*/
.add-img-user .p-image {
  position: static;
  text-align: left;
  display: block;
  height: auto;
  width: auto;
  background: transparent;
  margin-top: 20px;
}

.img-extension a,
.img-extension span {
  color: var(--iq-icon-color);
  padding: 0 3px;
  font-size: 13px;
}

/*---------------------------------------------------------------------
  Horizontal Menu Page
  -----------------------------------------------------------------------*/
.iq-page-menu-horizontal .iq-sidebar-logo {
  padding-top: 0;
  width: auto;
  display: block;
}

.iq-page-menu-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
  display: block;
}

/*---------------------------------------------------------------------
index Page
-----------------------------------------------------------------------*/
.iq-welcome-block-data {
  padding: 16px 0;
}

.iq-welcome-block-data .img-element {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.iq-welcome-block-data img.img-element.bottom {
  top: auto;
}

.iq-welcome-block-data .img-element.right-img {
  left: auto;
}

.iq-welcome-block-data .img-element.top-left {
  left: auto;
  bottom: auto;
  top: -50px;
}

.iq-welcome-block-data .img-element.left-img {
  width: 100%;
}

/*---------------------------------------------------------------------
Dashboard 1
-----------------------------------------------------------------------*/
.profile-icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50%;
}

.icon.icon-box {
  font-size: 40px;
  line-height: 50px;
}

.user-img span.avatar-status {
  bottom: -10px;
  right: -5px;
}

.left-icon-box a {
  text-align: center;
  display: block;
  font-size: 50px;
  width: 120px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

/* progress bar */
.progress-round {
  width: 50px;
  height: 50px;
  background: none;
  position: relative;
}

.progress-round::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--iq-light-border);
  position: absolute;
  top: 0;
  left: 0;
}

.progress-round > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress-round .progress-left {
  left: 0;
}

.progress-round .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress-round .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress-round .progress-right {
  right: 0;
}

.progress-round .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress-round .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

#home-chart-03 .apexcharts-legend.center.position-right {
  right: 35px !important;
}

/*---------------------------------------------------------------------
Dashboard - 2
-----------------------------------------------------------------------*/
.iq-iconbox {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  border-radius: 8px;
}

.iq-scale-border {
  height: 50px;
  width: 50px;
  border: 8px solid;
  border-radius: 50%;
}

.iq-scale-border.like-block {
  border-color: var(--iq-light-primary);
}

.iq-scale-border.comment-block {
  border-color: var(--iq-light-info);
}

.iq-scale-border.share-block {
  border-color: #fedc8a;
}

.iq-scale-border.repost-block {
  border-color: #fa847b;
}

.iq-scale-border.view-block {
  border-color: #56d172;
}

.iq-scale-border.post-block {
  border-color: #cacaca;
}

ul#post-slider .slick-arrow {
  top: -64px;
  background: transparent;
  height: 30px;
  width: 30px;
  color: var(--iq-icon-color);
  border: 1px solid var(--iq-dark-border);
  padding: 0;
  line-height: 30px;
  text-align: center;
}

ul#post-slider .slick-arrow.left {
  left: auto;
  right: 35px;
}

.iq-caption {
  position: absolute;
  top: 30px;
  left: 50px;
  color: var(--iq-white);
}

.iq-caption h1 {
  color: var(--iq-white);
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
}

.iq-caption p {
  color: var(--iq-white);
  font-size: 20px;
  letter-spacing: 4.2px;
}

div#stats {
  display: none;
}

.d-flex.stats-data {
  display: none !important;
}

.ais-SearchBox-form {
  background: var(--iq-body-bg);
  border-radius: 8px;
}

/*---------------------------------------------------------------------
small sidebar with icon
-----------------------------------------------------------------------*/
body.icon-with-text .iq-sidebar-logo {
  padding-left: 0;
}

body.icon-with-text .iq-sidebar-logo a {
  display: block;
  text-align: center;
}

.two-sidebar .iq-sidebar-small {
  z-index: 1030;
  position: fixed;
  width: 60px;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background: var(--iq-white);
}

.two-sidebar .iq-sidebar {
  margin-left: 60px;
}

.two-sidebar .content-page {
  margin-left: 320px;
}

.two-sidebar .iq-sidebar-logo {
  width: 320px;
}

.two-sidebar .iq-footer {
  margin-left: 320px;
}

.two-sidebar .iq-menu-bt {
  margin: 0 auto;
  line-height: 17px;
  font-size: 20px;
}

body.two-sidebar.sidebar-main .iq-sidebar-small .iq-menu-bt {
  opacity: 1;
  display: block;
}

body.two-sidebar.sidebar-main .iq-sidebar {
  width: 0;
}

body.two-sidebar.sidebar-main .content-page,
body.sidebar-main .iq-footer {
  margin-left: 60px;
}

body.two-sidebar.sidebar-main .iq-sidebar-logo {
  width: 60px;
}

.sidebar-menu-icon {
  display: flex;
  flex-direction: column;
}

.sidebar-menu-icon > a {
  font-size: 18px;
  display: block;
  padding: 5px 0;
  color: var(--iq-white);
}

.menu-open {
  opacity: 0;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
}

.wrapper-menu.open .menu-close {
  opacity: 0;
}

.wrapper-menu.open .menu-open {
  opacity: 1;
}

.two-sidebar.sidebar-main .wrapper-menu.open {
  transform: rotate(0deg);
}

.icon-with-text .iq-sidebar-menu .iq-menu li.active {
  margin: 0;
}

/*---------------------------------------------------------------------
Product Detail Page
-----------------------------------------------------------------------*/
.product-description {
  padding: 20px 20px 0;
}

.ratting {
  margin: 0 0 10px;
  font-size: 18px;
  color: var(--iq-primary);
}

.additional-product-action .product-action .add-to-cart a {
  width: auto;
  padding: 0 20px;
  background: rgba(130, 122, 243, 1) !important;
  color: var(--iq-white);
}

.additional-product-action .product-action .wishlist a {
  width: auto;
  padding: 0 20px;
  background: var(--iq-body-bg);
  color: var(--iq-primary);
}

.description-tabs {
  margin-top: 100px;
}

.iq-product-cover img,
.iq-additional_slider img {
  border-radius: 5px;
}

.description-tabs .nav-item .nav-link {
  font-size: 18px;
}

#related-slider .slick-arrow {
  top: 25%;
  height: 40px;
  width: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
}

.btn-increment button,
.btn-increment input {
  background: transparent;
  border: 1px solid var(--iq-light-border);
}

ul.grid_list {
  list-style: none;
}

ul.grid_list li a {
  background: #e5f2ff;
  color: var(--iq-primary);
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
}

.Products ul.product_list {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
}

.product-miniature {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.thumbnail-container {
  position: relative;
  overflow: hidden;
}

.product-action {
  position: relative;
  float: left;
  transition: all 0.45s ease 0s;
}

.Products li:hover .product-miniature .product-action {
  transition: all 0.45s ease 0s;
}

.product-action > div {
  display: inline-block;
}

.product-action a {
  background: #e5f2ff;
  color: var(--iq-primary);
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.regular-price {
  color: var(--iq-black);
  font-size: 20px;
  margin-bottom: 10px;
}

.regular-price span {
  color: var(--iq-icon-color);
  text-decoration: line-through;
}

.shipping span,
.stock span {
  color: var(--iq-black);
}

input.increment {
  text-align: center;
}

.ratting {
  margin: 0 0 10px;
  font-size: 18px;
  color: var(--iq-primary);
}

.thumbnail-container img {
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
}

.thumbnail-container .new {
  position: absolute;
  top: 15px;
  left: auto;
  right: 15px;
  background: var(--iq-white);
  font-size: 12px;
  padding: 2px 7px;
  border-radius: 4px;
}

.iq-checkout-policy .iq-checkout-icon {
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 20px;
  text-align: center;
}

.iq-checkout-policy ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.iq-checkout-policy ul li:last-child {
  border: none;
}

/*additional slider */
.iq-additional_slider {
  margin: 15px 0;
}

.iq-additional_slider li {
  padding: 0 10px;
}

.iq-additional_slider img {
  height: 100px;
  width: 150px;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  background: rgba(130, 122, 243, 1);
  border-color: var(--iq-primary) !important;
  color: var(--iq-white) !important;
}

.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
  color: var(--iq-primary) !important;
}

/*--------Extra ----------*/
.sell-list {
  -webkit-box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
  box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
  border-left: 4px solid;
}

.icon-date {
  position: relative;
  height: 80px;
  width: 65px;
  text-align: center;
  line-height: 15px;
  padding-top: 7px;
}

.icon-dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: absolute;
  left: 36%;
  bottom: -9px;
  text-align: center;
  border: 2px solid var(--iq-white);
}

/* table style extra  */
.list-1 {
  background-color: rgba(153, 248, 255, 0.2);
}

.list-2 {
  width: 150px;
}

/* swiper */
.related-product-block .e-commerce-product .swiper-button-next,
.related-product-block .e-commerce-product .swiper-button-prev {
  top: 25%;
  height: 40px;
  width: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  background: var(--iq-primary);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px !important;
  font-weight: 600;
  color: var(--white);
}

.d2-post-slider .swiper-button-next,
.d2-post-slider .swiper-button-prev {
  top: 40px;
  border-radius: 5px;
  background: var(--iq-primary);
  height: 30px;
  width: 25px;
  background: var(--iq-primary);
}

.d2-post-slider .swiper-button-next {
  right: 30px;
}

.d2-post-slider .swiper-button-prev {
  left: auto;
  right: 60px;
}

.d2-post-slider .swiper-button-next:after,
.d2-post-slider .swiper-button-prev:after {
  font-size: 12px !important;
  font-weight: 600;
}

.MuiTypography-colorInherit {
  color: #fff !important;
}

.MuiSelect-select {
  color: #fff !important;
}

.MuiSelect-icon {
  color: #fff !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #9a9cab !important;
}

.MuiIconButton-root {
  color: #fff !important;
}

.MuiPaper-root {
  background-color: #253942 !important;
  color: #fff !important;
}

.MuiTablePagination-root {
  /* background-color: rgba(34, 31, 58, 1) !important; */
}

.form-control:active {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #ffff;
}

.MuiButtonBase-root {
  border: none;
}

.sun-editor {
  background-color: #203235 !important;
  color: #928f8f !important;
  border: none !important;
  border-radius: 11px !important;
}

.sun-editor-editable {
  background-color: #203235 !important;
  color: rgba(255, 255, 255) !important;
  font-size: 18px !important;
  /* color: hsl(253deg 61% 85%) !important; */
  /* color: #928f8f !important; */
  border: none !important;
}

.sun-editor .se-toolbar {
  background-color: #203235 !important;
  border: none !important;
}

.sun-editor .se-resizing-bar {
  background-color: #203235 !important;
  border: none !important;
}

.sun-editor .se-toolbar {
  outline: none !important;
}

.sun-editor button {
  color: #fff !important;
}

.sun-editor .se-btn:enabled:focus,
.sun-editor .se-btn:enabled:hover {
  background-color: #e1e1e1;
  color: #1e2f32 !important;
  border-color: #d1d1d1;
  outline: 0 none;
}

.searchWrapper {
  background-color: #1e2f32 !important;
  border: none !important;
  color: #fff !important;
}

.optionListContainer {
  background-color: #1e2f32 !important;
}

.multiSelectContainer ul {
  border-radius: 0px !important;
  border: 1px solid #616161 !important;
}

.multiSelectContainer li {
  padding: 0 !important;
  padding-left: 5px !important;
}

#search_input {
  color: #fff !important;
}

.dropdown {
  max-height: 30px;
  margin-top: 0;
  font-size: 12px;
  max-width: 152px;
}

.dropdown-menu {
  margin-top: 5px;
  max-height: 350px;
  overflow: auto;
  max-width: 100px;
  padding-left: 10px;
}

.iq-card-body {
  padding: 7px 15px;
}

.css-b62m3t-container {
  width: 542px !important;
  margin: 0 auto;
  border-radius: 5px !important;
}

.sun-editor .se-toolbar {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  border-top-left-radius: 40%;
  border-top-right-radius: 40% !important;
}

.dashboard-card-icon {
  width: 25px;
  height: 25px;
  display: block;
  margin: auto;
}

.dashCard-font-size {
  font-size: 16px;
  color: #ffff;
}

.dashCard-number-font-size {
  font-size: 20px;
  font-weight: 800;
  color: #ffff;
}

.dashCard-text-user,
.dashCard-text-movie,
.dashCard-text-revenue,
.dashCard-text-webSeries {
  position: relative;
}

/* .dashCard-text-user::before,
.dashCard-text-movie::before,
.dashCard-text-webSeries::before,
.dashCard-text-revenue::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 9px;

  border: 1px solid #fdfdfd;
  border-width: 1px;
}

.dashCard-text-user::before {
  width: 100px;
}

.dashCard-text-movie::before {
  width: 62px;
}

.dashCard-text-webSeries::before {
  width: 100px;
}

.dashCard-text-revenue::before {
  width: 77px;
} */

.text-underline {
  text-decoration: underline;
  text-underline-position: under;
}

/* react select dropdwon custom css */
.css-13cymwt-control {
  background-color: #112935 !important;
  border-color: transparent !important;
  color: #fff !important;
  font-size: 15px !important;
}

.css-1nmdiq5-menu {
  background-color: #112935 !important;
  border-color: transparent !important;
  color: #fff !important;
  font-size: 15px !important;
}

.css-1dimb5e-singleValue {
  color: #fff !important;
}

.css-t3ipsp-control {
  background-color: #112935 !important;
  border-color: transparent !important;
  color: #fff !important;
  font-size: 15px !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover {
  background-color: #162933 !important;
  color: #fff !important;
  border-color: #162933 !important;
}

.css-166bipr-Input {
  color: #fff !important;
  font-size: 15px !important;
}

.css-1nmdiqs-menu:hover {
  background-color: #cacaca !important;
}

.css-1cfo1cf {
  color: #fff !important;
}

.daterangepicker td.in-range {
  background-color: #10263cc7 !important;
  color: #fff !important;
}

.daterangepicker td.start-date {
  background-color: #3649ac !important;
  color: #fff !important;
}

.daterangepicker td.end-date {
  background-color: #3649ac !important;
  color: #fff !important;
}

.daterangepicker td.available:hover {
  background-color: rgb(55, 59, 90) c7 !important;
  color: #fff !important;
}

.sun-editor-editable span,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
p {
  background-color: transparent !important;
  color: #fff !important;
  border: none !important;
}

.sun-editor .se-list-layer.se-list-format {
  background-color: #253942 !important;
}

.custom-editor-class * {
  background-color: transparent !important;
  /* Override background color */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red !important;
}

.help-center-scroll {
  max-height: 660px;
  overflow-y: auto;
}

.error {
  color: #ff4040;
  font-size: 16px;
}

.swal2-container {
  z-index: 9999 !important;
}

.apexcharts-menu-icon {
  display: none !important;
}

.edit-movie .nav-item .active {
  background-color: red;
}

.dashboard_active_card {
  border-radius: 10px;
  box-shadow: rgb(71 62 114 / 16%) 0px 1px 2px 0px,
    rgb(101 70 210 / 41%) 0px 2px 6px 2px;
}

table tr th:first-child {
  border-radius: 20px 0 0 0;
  overflow-x: hidden;
}

table tr th:last-child {
  border-radius: 0 20px 0 0;
  overflow-x: hidden;
}

.card-background {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: linear-gradient(
    137deg,
    rgba(28, 50, 52, 1) 0%,
    rgba(50, 64, 60, 1) 100%
  );
}

/* .dash-card {
  box-shadow: 0px 2px 8px #18141f1a, 0px 8px 16px #18141f26;
  background-color: #24243e61;
  border-radius: 10px;
} */
.optionContainer {
  color: #ffff;
}

.loader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

.card_border {
  border: 1.5px solid hsl(0deg 0% 100% / 49%);
  border-radius: 28px;
}

#input-search {
  width: 368px;
  box-shadow: rgb(255 255 255 / 30%) 0px 0px 0px 1.5px;
}

.iq-card-header-toolbar {
  border: 1px solid hsl(0deg 0% 100% / 37%);
  border-radius: 6px;
}

.MuiDialogContent-root {
  font-size: 16px;
}

.makeStyles-root-2 > .MuiPaper-rounded {
  margin-bottom: 14px !important;
}

table tbody {
  font-size: 18px;
}

.apexcharts-xaxis-label {
  font-weight: 100;
}

input[type="date"] {
  color-scheme: dark;
}

.img_Border {
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
}

.select_thumbnail {
  border: 2px dashed #3a4b53;
  border-radius: 15px;
  height: 170px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 170px;
}

.select_image {
  border: 2px dashed #3a4b53;
  border-radius: 15px;
  height: 280px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 745px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 320px) {
  .dashboard-card {
    background-image: url(../images/dashbaordMobile.png);
    width: 267px;
    height: 147px;
  }

  .counter {
    font-size: 30px;
  }
}

.selected-week {
  background-color: #c7e9fc;
  /* Change this to your desired background color */
  border-radius: 50%;
}

.roleSlider > .slick-next {
  right: -12px !important;
}
.roleSlider > .slick-prev {
  left: -12px;
}
.activeBtn {
  background-color: #ff8b00;
  color: #fff;
}
.activeBtn:hover{
  color: #fff;
}
.noneBtn {
  background-color: #0d2226;
  color: #ffffffcc;
}
.noneBtn:hover{
  color: #ffffffcc;
}


.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: .9s;
  --uib-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
